const initialState = {
  CustAppId: '',
  RegNoVerified: false,
  ErrorOccured: false,
  vehicleConfirmed: false,
  addressConfirmed: false,
  currentStatus: 0,
  fitmentDate: '',
  paymentLink: '',
  paymentStatus: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'VERIFY_VEHICLE_REG':
      return {
        ...state,
        RegNoVerified: action.value,
      };
    case 'ERROR_OCCURED':
      return {
        ...state,
        ErrorOccured: action.value,
      };
    case 'RERUN_CONFIRMATIONS':
      return {
        ...state,
        RerunConfirmations: action.value,
      };
    case 'SET_CUSTAPPID':
      return {
        ...state,
        CustAppId: action.value,
      };
    case 'CONFIRM_VEHICLE':
      return {
        ...state,
        vehicleConfirmed: action.value,
      };
    case 'CONFIRM_ADDRESS':
      return {
        ...state,
        addressConfirmed: action.value,
      };
    case 'SET_CLAIM_STATUS':
      return {
        ...state,
        currentStatus: action.value,
      };
    case 'SET_FITMENT_DATE':
      return {
        ...state,
        fitmentDate: action.value,
      };
    case 'SET_PAYMENT_LINK':
      return {
        ...state,
        paymentLink: action.value,
      };
    case 'SET_PAYMENT_STATUS':
      return {
        ...state,
        paymentStatus: action.value,
      };
    default:
      break;
  }

  return state;
};

export default reducer;
