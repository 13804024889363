import axios from 'axios-es6';

import Database from './Database';
import global from '../global';

const confirmVehicle = (callback) => {
  try {
    Database.ConfirmVehicle()
      .then(() => Database.FetchCustAppId())
      .then((CustAppId) => {
        if (navigator.onLine) {
          return axios.post(`${global.BASE_URL}CustomerApp/ConfirmVehicle/${CustAppId}`);
        }
        return true;
      })
      .then(() => {
        callback(null);
      })
      .catch((error) => {
        callback(error);
      });
  } catch (error) {
    callback(error);
  }
};

const addLicenceDisc = (image, callback) => {
  try {
    Database.AddLicenceDisc(image)
      .then(() => Database.FetchCustAppId())
      .then((CustAppId) => {
        if (navigator.onLine) {
          return axios.post(
            `${global.BASE_URL}CustomerApp/AddLicenceDisc`,
            {
              Image: image,
              CustAppId,
            },
          );
        }
        return true;
      })
      .then(() => {
        callback(null);
      })
      .catch((error) => {
        callback(error);
      });
  } catch (error) {
    callback(error);
  }
};

const saveVehicleImage = (image, imageName) => new Promise(async (resolve, reject) => {
  try {
    const CustAppId = await Database.FetchCustAppId();
    const success = await axios.post(`${global.BASE_URL}CustomerApp/SaveVehicleImage`, {
      CustAppId,
      Image: image,
      ImageName: imageName,
    });

    resolve(success.data);
  } catch (error) {
    console.log(error);
    reject(error);
  }
});

const lightstoneSearch = async (vin) => {
  const vehicle = await axios.get(`${global.BASE_URL}/vehicleSearchByVinNo/${vin}`);
  return vehicle.data;
};

const audatexSearch = async (vin, carId) => {
  const vehicle = await axios.get(`${global.BASE_URL}/getOeFromVin/${vin}/${carId}`);
  return vehicle.data;
};

export default {
  confirmVehicle,
  addLicenceDisc,
  saveVehicleImage,
  lightstoneSearch,
  audatexSearch,
};
