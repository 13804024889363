import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import Parser from 'ua-parser-js';

import './Camera.css';

const getBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    resolve(reader.result);
  };
  reader.onerror = (error) => {
    reject(error);
  };
});

class Camera extends React.Component {
  constructor(props) {
    super(props);

    this.initialiseMedia = this.initialiseMedia.bind(this);
    this.takePhoto = this.takePhoto.bind(this);
    this.selectPhoto = this.selectPhoto.bind(this);

    this.state = {
      container: this.props.container,
    };
  }

  componentDidMount() {
    this.initialiseMedia();
  }

  initialiseMedia() {
    const parser = new Parser();
    const results = parser.getResult();

    if (results.os.name === 'iOS') {
      $(`#${this.props.container} #pick-image`).css('display', 'block');
      $(`#${this.props.container} #overlay`).css('display', 'none');
      $(`#${this.props.container} #capture`).css('display', 'none');
    } else {
      if (!('mediaDevices' in navigator)) {
        navigator.mediaDevices = {};
      }

      if (!('getUserMedia' in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = (constraints) => {
          const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

          if (!getUserMedia) {
            return Promise.reject();
          }

          return new Promise((resolve, reject) => {
            getUserMedia.call(navigator, constraints, resolve, reject);
          });
        };
      }

      navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } })
        .then((stream) => {
          const videoPlayer = document.querySelector(`#${this.state.container} #player`);
          videoPlayer.srcObject = stream;
          videoPlayer.style.display = 'block';
        })
        .catch(() => {
          console.log(this.state.container);
          $(`#${this.props.container} #pick-image`).css('display', 'block');
          $(`#${this.props.container} #overlay`).css('display', 'none');
          $(`#${this.props.container} #capture`).css('display', 'none');
        });
    }
  }

  takePhoto() {
    const canvasElement = document.querySelector(`#${this.state.container} #canvas`);
    const videoElement = document.querySelector(`#${this.state.container} #player`);
    canvasElement.style.display = 'block';
    videoElement.style.display = 'none';

    const context = canvasElement.getContext('2d');
    context.drawImage(
      videoElement, 0, 0, canvasElement.width,
      videoElement.videoHeight / (videoElement.videoWidth / canvasElement.width),
    );
    videoElement.srcObject.getVideoTracks().forEach((track) => {
      track.stop();
    });
    this.props.onPhotoCaptured(canvasElement.toDataURL('image/jpeg'));
  }

  async selectPhoto(event) {
    const file = event.target.files[0];
    const fileBase64 = await getBase64(file);
    this.props.onPhotoCaptured(fileBase64);
  }

  render() {
    return (
      <div id="camera">
        <video id="player" autoPlay width="800px" height="600px" />
        <div id="overlay"><img src={this.props.overlay} alt="" /></div>
        <canvas id="canvas" width="800px" height="600px" />
        <button className="btn camera-btn " id="capture" onClick={this.takePhoto}>Capture</button>
        <div id="pick-image">
          <h6>Select an image to use:</h6>
          <input type="file" accept="image/*" id="image-picker" onChange={this.selectPhoto} />
        </div>
      </div>
    );
  }
}

Camera.propTypes = {
  onPhotoCaptured: PropTypes.func.isRequired,
  container: PropTypes.string.isRequired,
  overlay: PropTypes.string.isRequired,
};

export default Camera;
