import global from '../global';

const DatabaseExists = () => new Promise((resolve) => {
  try {
    const request = window.indexedDB.open('Autoboys', global.DB_VER);
    request.onupgradeneeded = (e) => {
      e.target.transaction.abort();
      window.indexedDB.deleteDatabase('Autoboys');
      resolve(false);
    };

    request.onsuccess = () => {
      resolve(true);
    };
  } catch (error) {
    window.indexedDB.deleteDatabase('Autoboys');
    resolve(false);
  }
});

const CreateLocalDatabase = (dbExists) => new Promise((resolve, reject) => {
  // HANNES: We only want to create a new offline database if one
  // doesn't already exist.
  if (dbExists === false) {
    const db = window.indexedDB.open('Autoboys', global.DB_VER);

    db.onsuccess = (event) => {
      console.log('Autoboys database successfully created.');
      event.target.result.close();
      resolve();
    };

    db.onerror = (event) => {
      reject(event.target.errorCode);
    };

    db.onupgradeneeded = (event) => {
      const database = event.target.result;
      database.createObjectStore('Claim', { autoIncrement: true });
      database.createObjectStore('Outlet', { autoIncrement: true });
      database.createObjectStore('Insured', { autoIncrement: true });
      database.createObjectStore('Comments', { autoIncrement: true });
      database.createObjectStore('Vehicle', { autoIncrement: true });
      database.createObjectStore('Fitment', { autoIncrement: true });
      database.createObjectStore('ServiceRating', { autoIncrement: true });

      // Create app settings table.
      const appSettings = database.createObjectStore('AppSettings', { autoIncrement: true });
      appSettings.add({
        showTutorial: true,
        verifiedVehicleReg: false,
      });
    };
  } else {
    resolve();
  }
});

const openDatabase = (onSuccess, onError) => {
  const db = window.indexedDB.open('Autoboys', global.DB_VER);

  db.onsuccess = (event) => onSuccess(event.target.result);

  if (onError) {
    db.onerror = (event) => onError(event.target.errorCode);
  } else {
    db.onerror = (event) => {
      console.log('Error: ', event.target.errorCode);
    };
  }
};

// HANNES: The intro tour has been displayed,  update the appsettings so
// that we do not run it after it has been completed
const DisableIntroTour = () => new Promise((resolve, reject) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('AppSettings', 'readwrite');
      const objectStore = transaction.objectStore('AppSettings');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.showTutorial = false;

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Vehicle Registration Verified.');
          };
        }
      };

      transaction.oncomplete = () => {
        resolve();
      };
    };

    const onError = (errorCode) => {
      reject(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    reject(error);
  }
});

// HANNES: Check the appsettings if we need to run the intro tour when the page opens.
const ShowIntroTour = () => new Promise((resolve, reject) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('AppSettings', 'readonly');
      const openCursor = transaction.objectStore('AppSettings').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const appSettings = cursor.value;
          resolve(appSettings.showTutorial);
        }

        resolve(false);
      };
    };

    const onError = (errorCode) => {
      reject(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    reject(error);
  }
});

// HANNES: If we have a vehicle registration number in the offline database,
// we assume that the registration number has been verified.
const IsVehicleRegVerified = () => new Promise((resolve, reject) => {
  const onSuccess = (targetDb) => {
    const transaction = targetDb.transaction('AppSettings', 'readonly');
    const openCursor = transaction.objectStore('AppSettings').openCursor();

    openCursor.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const appSettings = cursor.value;
        resolve(appSettings.verifiedVehicleReg);
      }

      resolve(false);
    };
  };

  const onError = (errorCode) => {
    reject(errorCode);
  };

  openDatabase(onSuccess, onError);
});

// HANNES: Write the verified registration number to the offline database.
const VerifyVehicleReg = (vehicleReg) => new Promise((resolve, reject) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('AppSettings', 'readwrite');
      const objectStore = transaction.objectStore('AppSettings');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.verifiedVehicleReg = vehicleReg;

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Vehicle Registration Verified.');
          };
        }
      };

      transaction.oncomplete = () => {
        resolve();
      };
    };

    const onError = (errorCode) => {
      reject(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    reject(error.message);
  }
});

const WriteInitialData = (data) => new Promise((resolve, reject) => {
  try {
    if (data !== null) {
      const claimInformation = data;

      const doWork = (targetDb) => {
        // Add claim information
        const claimObjectStore = targetDb
          .transaction('Claim', 'readwrite')
          .objectStore('Claim');

        const emptyClaimObjectStore = claimObjectStore.clear();
        emptyClaimObjectStore.onsuccess = () => {
          claimObjectStore.add(claimInformation.Claim);
        };

        // Add outlet information
        const outletObjectStore = targetDb
          .transaction('Outlet', 'readwrite')
          .objectStore('Outlet');

        const emptyOutletObjectStore = outletObjectStore.clear();
        emptyOutletObjectStore.onsuccess = () => {
          outletObjectStore.add(claimInformation.Outlet);
        };

        // Add insured information
        const insuredObjectStore = targetDb
          .transaction('Insured', 'readwrite')
          .objectStore('Insured');

        const emptyInsuredObjectStore = insuredObjectStore.clear();
        emptyInsuredObjectStore.onsuccess = () => {
          insuredObjectStore.add(claimInformation.Insured);
        };

        // Add vehicle information
        const vehicleObjectStore = targetDb
          .transaction('Vehicle', 'readwrite')
          .objectStore('Vehicle');

        const emptyVehicleObjectStore = vehicleObjectStore.clear();
        emptyVehicleObjectStore.onsuccess = () => {
          vehicleObjectStore.add(claimInformation.Vehicle);
        };

        // Add fitment information
        const fitmentObjectStore = targetDb
          .transaction('Fitment', 'readwrite')
          .objectStore('Fitment');

        const emptyFitmentObjectStore = fitmentObjectStore.clear();
        emptyFitmentObjectStore.onsuccess = () => {
          fitmentObjectStore.add(claimInformation.Fitment);
        };

        console.log('Successfully added claim data to local database.');

        resolve(null);
      };

      const onError = (errorCode) => {
        console.log('Error: ', errorCode);
        const req = window.indexedDB.deleteDatabase('Autoboys');
        req.onsuccess = () => {
          console.log('Deleted database successfully');
        };
        req.onerror = () => {
          console.log("Error: Couldn't delete database");
        };
        req.onblocked = () => {
          console.log("Error: Couldn't delete database due to the operation being blocked");
        };
      };

      openDatabase(doWork, onError);
    } else {
      resolve(null);
    }
  } catch (error) {
    reject(error);
  }
});

const FetchAllData = () => new Promise((resolve, reject) => {
  try {
    const doWork = (targetDb) => {
      const data = {
        Claim: {},
        Insured: {},
        Outlet: {},
        Vehicle: {},
        Fitment: {},
      };

      targetDb
        .transaction('Claim', 'readonly')
        .objectStore('Claim')
        .openCursor().onsuccess = (evtClaim) => {
          const cursor = evtClaim.target.result;
          if (cursor) {
            data.Claim = cursor.value;
          }

          targetDb
            .transaction('Insured', 'readonly')
            .objectStore('Insured')
            .openCursor().onsuccess = (evtInsured) => {
              const cursorInsured = evtInsured.target.result;
              if (cursorInsured) {
                data.Insured = cursorInsured.value;
              }

              targetDb
                .transaction('Outlet', 'readonly')
                .objectStore('Outlet')
                .openCursor().onsuccess = (evtOutlet) => {
                  const cursorOutlet = evtOutlet.target.result;
                  if (cursorOutlet) {
                    data.Outlet = cursorOutlet.value;
                  }

                  targetDb
                    .transaction('Vehicle', 'readonly')
                    .objectStore('Vehicle')
                    .openCursor().onsuccess = (evtVehicle) => {
                      const cursorVehicle = evtVehicle.target.result;
                      if (cursorVehicle) {
                        data.Vehicle = cursorVehicle.value;
                      }

                      targetDb
                        .transaction('Fitment', 'readonly')
                        .objectStore('Fitment')
                        .openCursor().onsuccess = (evtFitment) => {
                          const cursorFitment = evtFitment.target.result;
                          if (cursorFitment) {
                            data.Fitment = cursorFitment.value;
                          }
                          if ('CustAppId' in data.Claim) {
                            resolve(data);
                          } else {
                            reject(new Error('Claim not in local database.'));
                          }
                        };
                    };
                };
            };
        };
    };

    openDatabase(doWork);
  } catch (error) {
    reject(error);
  }
});

const WriteComments = (data, callback) => {
  if (data !== null) {
    const doWork = (targetDb) => {
      // Open the comments object store
      const commentsObjectStore = targetDb
        .transaction('Comments', 'readwrite')
        .objectStore('Comments');

      // Clear the comments in the database
      const emptyCommentsObjectStore = commentsObjectStore.clear();
      emptyCommentsObjectStore.onsuccess = () => {
        data.forEach((item) => {
          commentsObjectStore.add(item);
        });
      };

      callback();
    };

    openDatabase(doWork);
  } else {
    callback();
  }
};

const FetchCustAppId = (CustAppId) => new Promise((resolve, reject) => {
  try {
    if (!CustAppId) {
      const doWork = (targetDb) => {
        targetDb
          .transaction('Claim', 'readonly')
          .objectStore('Claim')
          .openCursor().onsuccess = (evtClaim) => {
            const cursor = evtClaim.target.result;
            if (cursor) {
              resolve(cursor.value.CustAppId);
            }
          };
      };

      const onError = (errorCode) => {
        reject(errorCode);
      };

      openDatabase(doWork, onError);
    } else {
      resolve(CustAppId);
    }
  } catch (error) {
    reject(error);
  }
});

const FetchComments = (callback) => {
  try {
    const doWork = (targetDb) => {
      const data = [];

      const transaction = targetDb
        .transaction('Comments', 'readonly');

      const openCursor = transaction.objectStore('Comments').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          data.push(cursor.value);
          cursor.continue();
        }
      };

      transaction.oncomplete = () => {
        callback(null, data);
      };
    };

    openDatabase(doWork);
  } catch (error) {
    callback(error);
  }
};

const FetchOutletInformation = () => new Promise((resolve, reject) => {
  try {
    const doWork = (targetDb) => {
      let data = {};

      const transaction = targetDb
        .transaction('Outlet', 'readonly');

      const openCursor = transaction.objectStore('Outlet').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          data = cursor.value;
        }
      };

      transaction.oncomplete = () => {
        resolve(data);
      };
    };

    openDatabase(doWork);
  } catch (error) {
    reject(error);
  }
});

const VehicleExists = (callback) => {
  try {
    const onSuccess = (targetDb) => {
      let result = false;

      const transaction = targetDb
        .transaction('Vehicle', 'readonly');

      const openCursor = transaction.objectStore('Vehicle').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          result = true;
        }
      };

      transaction.oncomplete = () => {
        callback(null, result);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error);
  }
};

const WriteClaimVehicle = (vehicle, callback) => {
  try {
    if (vehicle) {
      const onSuccess = (targetDb) => {
        const vehicleObjectStore = targetDb
          .transaction('Vehicle', 'readwrite')
          .objectStore('Vehicle');

        const emptyVehicleObjectStore = vehicleObjectStore.clear();
        emptyVehicleObjectStore.onsuccess = () => {
          vehicleObjectStore.add(vehicle);

          callback(null);
        };
      };

      const onError = (errorCode) => {
        callback(errorCode);
      };

      openDatabase(onSuccess, onError);
    } else {
      callback(null);
    }
  } catch (error) {
    callback(error);
  }
};

const FetchVehicle = (callback) => {
  try {
    const onSuccess = (targetDb) => {
      let vehicle = {};

      const transaction = targetDb
        .transaction('Vehicle', 'readonly');

      const openCursor = transaction.objectStore('Vehicle').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          vehicle = cursor.value;
        }
      };

      transaction.oncomplete = () => {
        callback(null, vehicle);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error);
  }
};

const ConfirmVehicle = () => new Promise((resolve, reject) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('Vehicle', 'readwrite');
      const objectStore = transaction.objectStore('Vehicle');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.Confirmed = true;
          updateData.Synchronised = false;

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Vehicle confirmed.');
          };
        }
      };

      transaction.oncomplete = () => {
        resolve();
      };
    };

    const onError = (errorCode) => {
      reject(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    reject(error);
  }
});

const AddLicenceDisc = (image) => new Promise((resolve, reject) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('Vehicle', 'readwrite');
      const objectStore = transaction.objectStore('Vehicle');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.LicenceDisc = image;
          updateData.Synchronised = false;

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Licence disc updated.');
          };
        }
      };

      transaction.oncomplete = () => {
        resolve();
      };
    };

    const onError = (errorCode) => {
      reject(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    reject(error);
  }
});

const WriteFitment = (fitment, callback) => {
  if (fitment !== null) {
    try {
      const onSuccess = (targetDb) => {
        const transaction = targetDb.transaction('Fitment', 'readwrite');

        const vehicleObjectStore = transaction.objectStore('Fitment');

        const emptyVehicleObjectStore = vehicleObjectStore.clear();
        emptyVehicleObjectStore.onsuccess = () => {
          vehicleObjectStore.add(fitment);
        };

        transaction.oncomplete = () => {
          callback(null);
        };
      };

      const onError = (errorCode) => {
        callback(errorCode);
      };

      openDatabase(onSuccess, onError);
    } catch (error) {
      callback(error.message);
    }
  } else {
    callback(null);
  }
};

const FetchFitment = (callback) => {
  try {
    const onSuccess = (targetDb) => {
      let fitment = {};

      const transaction = targetDb
        .transaction('Fitment', 'readonly');

      const openCursor = transaction.objectStore('Fitment').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          fitment = cursor.value;
        }
      };

      transaction.oncomplete = () => {
        callback(null, fitment);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error.message);
  }
};

const ConfirmAddress = (callback) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('Fitment', 'readwrite');
      const objectStore = transaction.objectStore('Fitment');
      let addressType = '';

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.Confirmed = true;
          updateData.Synchronised = false;

          addressType = JSON.parse(JSON.stringify(updateData.AddressType));

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Fitment confirmed.');
          };
        }
      };

      transaction.oncomplete = () => {
        callback(null, addressType);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error.message);
  }
};

const WriteServiceRating = (serviceRating, callback) => {
  try {
    if (serviceRating) {
      const onSuccess = (targetDb) => {
        const objectStore = targetDb
          .transaction('ServiceRating', 'readwrite')
          .objectStore('ServiceRating');

        const emptyObjectStore = objectStore.clear();
        emptyObjectStore.onsuccess = () => {
          objectStore.add(serviceRating);

          callback(null);
        };
      };

      const onError = (errorCode) => {
        callback(errorCode);
      };

      openDatabase(onSuccess, onError);
    } else {
      callback(null);
    }
  } catch (error) {
    callback(error);
  }
};

const FetchServiceRating = (callback) => {
  try {
    const onSuccess = (targetDb) => {
      let serviceRating = {
        Rating: null,
        WouldRecommend: null,
        Synchronised: false,
      };

      const transaction = targetDb
        .transaction('ServiceRating', 'readonly');

      const openCursor = transaction.objectStore('ServiceRating').openCursor();

      openCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          serviceRating = cursor.value;
        }
      };

      transaction.oncomplete = () => {
        callback(null, serviceRating);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error.message);
  }
};

const SetRating = (rating, callback) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('ServiceRating', 'readwrite');
      const objectStore = transaction.objectStore('ServiceRating');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.Rating = rating;
          updateData.Synchronised = false;

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Service Rating logged.');
          };
        }
      };

      transaction.oncomplete = () => {
        callback(null, rating);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error.message);
  }
};

const ServiceRatingSynchronised = (isSynchronised, callback) => {
  try {
    if (isSynchronised) {
      const onSuccess = (targetDb) => {
        const transaction = targetDb.transaction('ServiceRating', 'readwrite');
        const objectStore = transaction.objectStore('ServiceRating');

        objectStore.openCursor().onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            const updateData = cursor.value;
            updateData.Synchronised = true;

            const request = cursor.update(updateData);
            request.onsuccess = () => {
              console.log('Service Rating synchronised.');
            };
          }
        };

        transaction.oncomplete = () => {
          callback(null);
        };
      };

      const onError = (errorCode) => {
        callback(errorCode);
      };

      openDatabase(onSuccess, onError);
    } else {
      callback(null);
    }
  } catch (error) {
    callback(error.message);
  }
};

const ServiceRecommend = (recommend, callback) => {
  try {
    const onSuccess = (targetDb) => {
      const transaction = targetDb.transaction('ServiceRating', 'readwrite');
      const objectStore = transaction.objectStore('ServiceRating');

      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const updateData = cursor.value;
          updateData.WouldRecommend = recommend;
          updateData.Synchronised = false;

          const request = cursor.update(updateData);
          request.onsuccess = () => {
            console.log('Service Recommendation updated.');
          };
        }
      };

      transaction.oncomplete = () => {
        callback(null, recommend);
      };
    };

    const onError = (errorCode) => {
      callback(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    callback(error.message);
  }
};

const AllDataConfirmed = () => new Promise((resolve, reject) => {
  try {
    const onSuccess = (targetDb) => {
      let vehicleConfirmed = false;
      let addressConfirmed = false;

      const vehicleTransaction = targetDb
        .transaction('Vehicle', 'readonly');

      const vehicleCursor = vehicleTransaction.objectStore('Vehicle').openCursor();

      vehicleCursor.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          vehicleConfirmed = cursor.value.Confirmed;
        }
      };

      vehicleTransaction.oncomplete = () => {
        const addressTransaction = targetDb
          .transaction('Fitment', 'readonly');

        const addressCursor = addressTransaction.objectStore('Fitment').openCursor();

        addressCursor.onsuccess = (event) => {
          const cursor = event.target.result;
          if (cursor) {
            addressConfirmed = cursor.value.Confirmed;
          }
        };

        addressTransaction.oncomplete = () => {
          resolve(vehicleConfirmed && addressConfirmed);
        };
      };
    };

    const onError = (errorCode) => {
      reject(errorCode);
    };

    openDatabase(onSuccess, onError);
  } catch (error) {
    reject(error);
  }
});

export default {
  DatabaseExists,
  CreateLocalDatabase,
  WriteInitialData,
  FetchAllData,
  WriteComments,
  FetchComments,
  FetchCustAppId,
  FetchOutletInformation,
  VehicleExists,
  WriteClaimVehicle,
  FetchVehicle,
  ConfirmVehicle,
  AddLicenceDisc,
  WriteFitment,
  FetchFitment,
  ConfirmAddress,
  WriteServiceRating,
  FetchServiceRating,
  SetRating,
  ServiceRatingSynchronised,
  ServiceRecommend,
  AllDataConfirmed,
  IsVehicleRegVerified,
  VerifyVehicleReg,
  DisableIntroTour,
  ShowIntroTour,
};
