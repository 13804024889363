import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';

import './TopNavButton.css';

class TopNavButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonText: this.props.buttonText,
      linkto: this.props.linkto,
      confirmed: this.props.confirmed,
      active: this.props.active,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      ...newProps,
    });
  }

  render() {
    return (
      <Route
        render={({ history }) => (
          <div
            id={this.props.id}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.props.setCurrentTab(this.state.linkto);
              history.push(`/${this.state.linkto}`);
            }}
            onKeyPress={() => {
              this.props.setCurrentTab(this.state.linkto);
              history.push(`/${this.state.linkto}`);
            }}
            className={`col-xs-4 center top-nav-button ${this.state.active ? 'active' : ''}`}
          >
            <span>
              <FontAwesome name="exclamation" className={`not-confirmed ${this.state.confirmed ? 'hidden' : ''}`} />
&nbsp;
              {this.state.buttonText.toUpperCase()}
            </span>
          </div>
        )}
      />
    );
  }
}

TopNavButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  linkto: PropTypes.string.isRequired,
  confirmed: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default TopNavButton;
