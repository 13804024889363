import axios from 'axios-es6';
import _ from 'lodash';
import async from 'async';

import global from '../global';
import Database from './Database';

const FetchClaimData = () => new Promise((resolve, reject) => {
  if (!navigator.onLine) {
    resolve(null);
  } else {
    const CustAppId = window.location.search.substr(1, window.location.search.length - 1);

    if (CustAppId.length > 0) {
      axios.get(`${global.BASE_URL}CustomerApp/GetInitialClaimInformation/${CustAppId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      Database.FetchCustAppId()
        .then((Id) => axios.get(`${global.BASE_URL}CustomerApp/GetInitialClaimInformation/${Id}`))
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  }
});

const FetchComments = (callback) => {
  Database.FetchCustAppId()
    .then((CustAppId) => {
      if (navigator.onLine) {
        axios.get(`${global.BASE_URL}CustomerApp/GetNotifications/${CustAppId}`)
          .then((results) => {
            const comments = _.sortBy(results.data, ['CreateDate']);
            callback(null, comments);
          })
          .catch((error) => {
            callback(error);
          });
      } else {
        callback(null, null);
      }
    })
    .catch((error) => {
      callback(error);
    });
};

const SendContactRequest = () => new Promise((resolve, reject) => {
  Database.FetchCustAppId()
    .then((CustAppId) => axios.post(`${global.BASE_URL}CustomerApp/RequestContact/${CustAppId}`))
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
});

const PingContactUs = () => new Promise((resolve, reject) => {
  Database.FetchCustAppId()
    .then((CustAppId) => axios.get(`${global.BASE_URL}CustomerApp/PingContactUs/${CustAppId}`))
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
});

const SendComplaintsMessage = (message) => new Promise((resolve, reject) => {
  Database.FetchCustAppId()
    .then((CustAppId) => axios.post(`${global.BASE_URL}CustomerApp/SendComplaintMessage`, {
      CustAppId,
      Message: message,
    }))
    .then((result) => {
      resolve(result);
    })
    .catch((error) => {
      reject(error);
    });
});

const FetchAgentInfo = () => new Promise((resolve, reject) => {
  Database.FetchOutletInformation()
    .then((data) => {
      const info = {
        agentName: data.AssignedAgent,
        agentNumber: data.ContactNumber,
        outlet: data.Outlet,
      };
      resolve(info);
    })
    .catch((error) => {
      reject(error);
    });
});

const FetchClaimVehicle = (vehicleExists, callback) => {
  try {
    if (navigator.onLine || !vehicleExists) {
      Database.FetchCustAppId()
        .then((CustAppId) => axios.get(`${global.BASE_URL}CustomerApp/FetchClaimVehicle/${CustAppId}`))
        .then((result) => callback(null, result.data))
        .catch((error) => callback(error));
    } else {
      callback(null, null);
    }
  } catch (error) {
    callback(error);
  }
};

const FetchVehicleInformation = () => new Promise((resolve, reject) => {
  async.waterfall([
    Database.VehicleExists,
    FetchClaimVehicle,
    Database.WriteClaimVehicle,
    Database.FetchVehicle,
  ], (error, vehicle) => {
    if (error) {
      reject(error);
    } else {
      resolve(vehicle);
    }
  });
});

const LogCallToAgent = () => new Promise((resolve, reject) => {
  Database.FetchCustAppId()
    .then((CustAppId) => axios.post(`${global.BASE_URL}CustomerApp/LogClientContact/${CustAppId}`))
    .then(() => {
      resolve();
    })
    .catch((error) => {
      reject(error);
    });
});

const IsSantamClaim = (CustAppId) => new Promise(async (resolve, reject) => {
  try {
    if (CustAppId === '') {
      CustAppId = await Database.FetchCustAppId();
    }
    const isSantamClaim = await axios.get(`${global.BASE_URL}CustomerApp/ClaimIsSantam/${CustAppId}`);
    resolve(isSantamClaim.data);
  } catch (exception) {
    reject(exception);
  }
});

const FetchVehicleReg = (CustAppId) => new Promise(async (resolve, reject) => {
  try {
    const vehicleReg = await axios.get(`${global.BASE_URL}Claim/FetchVehicleReg/${CustAppId}`);
    resolve(vehicleReg.data.replace(/\s/g, ''));
  } catch (error) {
    reject(error);
  }
});

const VerifyVehicleReg = (CustAppId, reg) => new Promise(async (resolve, reject) => {
  try {
    const returnData = await axios.get(`${global.BASE_URL}Claim/VerifyVehicleReg/${CustAppId}/${reg}`);
    resolve(returnData.data);
  } catch (error) {
    reject(error);
  }
});
// YUSSUF: Retrieve payment link from the MySID API
const FetchPaymentLink = () => new Promise((resolve, reject) => {
  if (!navigator.onLine) {
    resolve(null);
  } else {
    const CustAppId = window.location.search.substr(1, window.location.search.length - 1);

    if (CustAppId.length > 0) {
      axios.get(`${global.BASE_URL}Claim/ExcessPaymentLink/${CustAppId}?return_url=https%3A%2F%2Fcustapp.mysid.co.za%2Fexcessinvoice&cancel_url=https%3A%2F%2Fcustapp.mysid.co.za%2F`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      Database.FetchCustAppId()
        .then((Id) => axios.get(`${global.BASE_URL}Claim/ExcessPaymentLink/${Id}?return_url=https%3A%2F%2Fcustapp.mysid.co.za%2Fexcessinvoice&cancel_url=https%3A%2F%2Fcustapp.mysid.co.za%2F`))
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  }
});
// YUSSUF: Retrieve excess status from the MySID API
const IsExcessPaid = () => new Promise((resolve, reject) => {
  if (!navigator.onLine) {
    resolve(null);
  } else {
    const CustAppId = window.location.search.substr(1, window.location.search.length - 1);

    if (CustAppId.length > 0) {
      axios.get(`${global.BASE_URL}Claim/IsCustomerExcessPaid/${CustAppId}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      Database.FetchCustAppId()
        .then((Id) => axios.get(`${global.BASE_URL}Claim/IsCustomerExcessPaid/${Id}`))
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  }
});
// YUSSUF: Retrieve Excess Invoice HTML from the MySID API
const FetchExcessInvoice = (excess) => new Promise((resolve, reject) => {
  if (!navigator.onLine) {
    resolve(null);
  } else {
    const CustAppId = window.location.search.substr(1, window.location.search.length - 1);

    if (CustAppId.length > 0) {
      axios.get(`${global.BASE_URL}Claim/PrerenderCustomerExcessInvoice?CustAppId=${CustAppId}&excess=${excess}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      Database.FetchCustAppId()
        .then((Id) => axios.get(`${global.BASE_URL}Claim/PrerenderCustomerExcessInvoice?CustAppId=${Id}&excess=${excess}`))
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  }
});
// YUSSUF: Update excess proforma invoice details with the MySID API
const CreateExcessInvoicePDF = (name, address) => new Promise((resolve, reject) => {
  if (!navigator.onLine) {
    resolve(null);
  } else {
    const CustAppId = window.location.search.substr(1, window.location.search.length - 1);

    if (CustAppId.length > 0) {
      axios.post(`${global.BASE_URL}Claim/UploadCustomerExcessInvoice?CustAppId=${CustAppId}&name=${encodeURI(name)}&address=${encodeURI(address)}`)
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      Database.FetchCustAppId()
        .then((Id) => axios.post(`${global.BASE_URL}Claim/UploadCustomerExcessInvoice?CustAppId=${Id}&name=${encodeURI(name)}&address=${encodeURI(address)}`))
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  }
});

export default {
  FetchClaimData,
  FetchComments,
  SendContactRequest,
  PingContactUs,
  SendComplaintsMessage,
  FetchAgentInfo,
  FetchVehicleInformation,
  LogCallToAgent,
  IsSantamClaim,
  FetchVehicleReg,
  VerifyVehicleReg,
  FetchPaymentLink,
  IsExcessPaid,
  FetchExcessInvoice,
  CreateExcessInvoicePDF,
};
