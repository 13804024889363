import React from 'react';
import PropTypes from 'prop-types';

import './OutletDetails.css';

class OutletDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      BranchName: this.props.BranchName,
      Csc: this.props.Csc,
      ContactNumber: this.props.ContactNumber,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      BranchName: newProps.BranchName,
      Csc: newProps.Csc,
      ContactNumber: newProps.ContactNumber,
    });
  }

  render() {
    return (
      <div className="col-xs-12" id="outlet-details">
        <div className="row">
          <div className="col-xs-12 small text-left">
            <h4 className="text-left">Outlet Details</h4>
          </div>
          <div className="col-xs-5 small text-left">
            <b>Outlet:</b>
          </div>
          <div
            className="col-xs-7 small text-left"
            role="button"
            tabIndex={0}
          >
            {this.state.BranchName}
          </div>
          <div className="col-xs-5 small text-left">
            <b>Agent Assigned:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {this.state.Csc}
          </div>
          <div className="col-xs-5 small text-left">
            <b>Contact Number:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {this.state.ContactNumber}
          </div>
        </div>
      </div>
    );
  }
}

OutletDetails.propTypes = {
  BranchName: PropTypes.string,
  Csc: PropTypes.string,
  ContactNumber: PropTypes.string,
};

OutletDetails.defaultProps = {
  BranchName: '',
  Csc: '',
  ContactNumber: '',
};

export default OutletDetails;
