import React from 'react';
import PropTypes from 'prop-types';

import Camera from '../../Camera/Camera';

import windscreen from '../../../assets/images/windshield.png';
import frontView from '../../../assets/images/frontcar.png';
import licenceDisc from '../../../assets/images/licencedisc.png';

class TakePhotoModal extends React.Component {
  constructor(props) {
    super(props);

    this.onPhotoCaptured = this.onPhotoCaptured.bind(this);

    this.state = {
      Image: null,
    };
  }

  onPhotoCaptured(photoBase64) {
    this.setState({
      Image: photoBase64,
    });
  }

  render() {
    const isLicenceDisc = this.props.ImageName === 'LicenceDisc' ? licenceDisc : frontView;
    const overlayImg = this.props.ImageName === 'Damage' ? windscreen : isLicenceDisc;
    return (
      <div id={`takePhotoModal${this.props.ImageName}`} className="modal fade in" style={{ display: 'block' }}>
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <Camera
                  container={`takePhotoModal${this.props.ImageName}`}
                  onPhotoCaptured={this.onPhotoCaptured}
                  overlay={overlayImg}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info"
                onClick={() => {
                  document.querySelector(`#takePhotoModal${this.props.ImageName} #image-picker`).style.display = 'none';
                  document.querySelector(`#takePhotoModal${this.props.ImageName} #player`).style.display = 'none';
                  document.querySelector(`#takePhotoModal${this.props.ImageName} #canvas`).style.display = 'none';
                  this.props.Hide();
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-succ"
                onClick={() => {
                  document.querySelector(`#takePhotoModal${this.props.ImageName} #image-picker`).style.display = 'none';
                  document.querySelector(`#takePhotoModal${this.props.ImageName} #player`).style.display = 'none';
                  document.querySelector(`#takePhotoModal${this.props.ImageName} #canvas`).style.display = 'none';
                  this.props.Action(this.state.Image, this.props.ImageName);
                  this.props.Hide();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TakePhotoModal.propTypes = {
  ImageName: PropTypes.string.isRequired,
  Action: PropTypes.func.isRequired,
  Hide: PropTypes.func.isRequired,
};

export default TakePhotoModal;
