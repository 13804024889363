import React from 'react';
import PropTypes from 'prop-types';

import '../../../../node_modules/font-awesome/css/font-awesome.css';

import RequestCall from '../../../assets/images/request-call.png';
import MessageComplaints from '../../../assets/images/message-complaints.png';
import CallAgent from '../../../assets/images/call-agent.png';

class ComplaintItem extends React.Component {
  constructor(props) {
    super(props);

    this.getImage = this.getImage.bind(this);

    this.state = {
      buttonText: this.props.buttonText,
      buttonImage: this.props.iconClass,
      onClick: this.props.onClick,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      buttonText: newProps.buttonText,
      buttonImage: newProps.iconClass,
      onClick: newProps.onClick,
    });
  }

  getImage() {
    switch (this.state.buttonImage) {
      case 'request-call':
        return RequestCall;
      case 'message-complaints':
        return MessageComplaints;
      case 'call-agent':
        return CallAgent;
      default:
        return null;
    }
  }

  render() {
    return (
      <div className={`col-xs-4  center ${this.props.offsetClass}`}>
        <div
          className="col-xs-12"
          role="button"
          onClick={this.state.onClick}
          onKeyPress={this.state.onClick}
          tabIndex={0}
        >
          <div className="row text-center">
            <img alt={this.state.buttonText} src={this.getImage()} />
          </div>
        </div>
        <div className="small">{this.state.buttonText}</div>
      </div>
    );
  }
}

ComplaintItem.propTypes = {
  buttonText: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  offsetClass: PropTypes.string,
};

ComplaintItem.defaultProps = {
  offsetClass: '',
};

export default ComplaintItem;
