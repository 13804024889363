/* eslint-disable */
import React from 'react';
import async from 'async';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import _ from 'lodash';
import { Route } from 'react-router-dom';
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';

import FitmentLogic from '../../Logic/Fitment';
import Database from '../../Logic/Database';
import ClaimStatus from '../common/ClaimStatus/ClaimStatus';
import TimeUpdateModal from './TimeUpdateModal';
// import AddressUpdateModal from './AddressUpdateModal';
import CurrentLocationModal from './CurrentLocationModal';

import './Fitment.css';

class Fitment extends React.Component {
  constructor(props) {
    super(props);

    this.confirmAddress = this.confirmAddress.bind(this);

    this.state = {
      fitment: {},
      showAlert: false,
    };
  }

  componentDidMount() {
    this.fetchFitmentDetails();
  }

  fitmentScheduledForToday(fitmentDateString) {
    const fitmentDate = new Date(fitmentDateString);
    if (fitmentDate.getFullYear() >= new Date().getFullYear()
      && fitmentDate.getMonth() >= new Date().getMonth()
      && fitmentDate.getDate() > new Date().getDate()) {
      return true;
    }
    return false;
  }

  fetchFitmentDetails() {
    async.waterfall(
      [
        FitmentLogic.FetchFitmentDetails,
        Database.WriteFitment,
        Database.FetchFitment,
      ],
      (error, fitment) => {
        if (error) {
          toastr.error(
            `Something went wrong and we were unable to fetch the fitment's details. Error: ${error.message}`,
            'Oops',
          );
        } else {
          this.setState({
            ...this.state,
            fitment,
          });
        }
      },
    );
  }

  confirmAddress() {
    async.waterfall(
      [
        Database.ConfirmAddress,
        FitmentLogic.ConfirmAddress,
        Database.FetchFitment,
      ],
      (error, fitment) => {
        if (error) {
          toastr.error(
            `Something went wrong and we were unable to fetch the fitment's details. Error: ${error.message}`,
            'Oops',
          );
        } else {
          this.props.confirmAddress(true);
          this.setState({
            fitment,
            showAlert: true,
          });
        }
      },
    );
  }

  async UpdateTime(newTime) {
    try {
      const success = await FitmentLogic.UpdateFitmentTime(newTime);
      if (!success) {
        throw new Error('Unable to update fitment time.');
      } else {
        toastr.success('A time update request has been sent to the agent handling this claim.', 'Success');
      }
    } catch (error) {
      toastr.error(error.message, 'Failed to update fitment time.');
    }
  }

  async UpdateAddress(newAddress) {
    try {
      const success = await FitmentLogic.UpdateFitmentAddress(newAddress);
      if (!success) {
        throw new Error('Unable to update fitment address.');
      } else {
        toastr.success('An address update request has been sent to the agent handling this claim.', 'Success');
      }
    } catch (error) {
      toastr.error(error.message, 'Failed to update fitment address.');
    }
  }

  async SendLocation(newAddress, Lat, Lng) {
    try {
      // TODO YUSSUF: Implement Logic Function
      const success = [];
      success.push(FitmentLogic.UpdateLocation(newAddress, Lat, Lng));
      success.push(FitmentLogic.UpdateFitmentAddress(newAddress));
      if (!success[0] || !success[1]) {
        throw new Error('Unable to update fitment location.');
      } else {
        toastr.success('Please note - Your request reflects a preferred fitment location change and will only be confirmed once one of our representatives contact you.', 'Success');
      }
    } catch (error) {
      toastr.error(error.message, 'Failed to update fitment location.');
    }
  }

  render() {
    let fitment;
    if (this.state.fitment) {
      fitment = (
        <div id="fitment-details col-xs-12">
          <div className="col-xs-12">
            <div className="row fitment-header">
              <div className="col-xs-6 text-right">Fitment Address</div>
              <div
                style={{ textTransform: 'capitalize' }}
                className={`col-xs-6 text-left ${this.state.fitment.Confirmed
                  ? 'confirmed'
                  : 'not-confirmed'}`}
              >
                <div className="row">
                  <div className="col-xs-12">
                    <FontAwesome
                      name={
                        this.state.fitment.Confirmed ? 'check' : 'exclamation'
                      }
                    />
                    {' '}
                    {this.state.fitment.Confirmed
                      ? 'Confirmed'
                      : 'Not Confirmed'}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="text-left row">
                  <div className="col-xs-6 text-right">
                    <b>Scheduled For:</b>
                  </div>
                  <div className="col-xs-6">
                    {this.state.fitment.FitmentDate
                      ? this.state.fitment.FitmentDate
                      : 'Not Scheduled'}
                  </div>
                </div>
                <div className="text-left row">
                  <div className="col-xs-6 text-right">
                    <b>Address:</b>
                  </div>
                  <div
                    className="col-xs-6"
                    role="button"
                    tabIndex={0}
                  >
                    {this.state.fitment.Address
                      ? this.state.fitment.Address
                        .split(', ')
                        .map((item, key) => (
                          <span key={key.toString()}>
                            {item}
                            <br />
                          </span>
                        ))
                      : ''}
                  </div>
                </div>
                <div className="text-left row">
                  <div className="col-xs-6 text-right">
                    <b>Special Instructions:</b>
                  </div>
                  <div className="col-xs-6">
                    {!_.isNull(this.state.fitment.Instructions)
                      ? this.state.fitment.Instructions
                      : 'None'}
                  </div>
                </div>
                <Route
                  render={() => (!this.state.fitment.Confirmed ? (
                    <div
                      style={{ marginTop: '10px' }}
                      className={`text-center row ${this.state.fitment
                        .Confirmed !== true
                        ? ''
                        : 'hidden'}`}
                    >
                      <div className="col-sm-6">
                        <button
                          className="btn btn-success col-xs-12"
                          onClick={this.confirmAddress}
                        >
                          Confirm Address
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button
                          className="btn btn-success col-xs-12"
                          data-toggle="modal"
                          data-target="#currentLocationModal"
                        >
                          Update Fitment Location
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>
                        {
                            !this.fitmentScheduledForToday(this.state.fitment.FitmentDate)
                              ? (
                                <span>
                                  <div style={{ marginTop: '10px' }} className="col-sm-6 col-sm-offset-3">
                                    <button
                                      className="btn btn-success col-xs-12"
                                      data-toggle="modal"
                                      data-target="#currentLocationModal"
                                    >
                                      Update Fitment Location
                                    </button>
                                  </div>
                                  <div style={{ marginTop: '10px' }} className="col-sm-6 col-sm-offset-3">
                                    <button
                                      className="btn btn-success col-xs-12"
                                      data-toggle="modal"
                                      data-target="#timeUpdateModal"
                                    >
                                      Update Time
                                    </button>
                                  </div>
                                </span>
                              )
                              : (
                                <span>
                                  <div style={{ marginTop: '10px' }} className="col-sm-6 col-sm-offset-3">
                                    <button
                                      className="btn btn-success col-xs-12"
                                      data-toggle="modal"
                                      data-target="#currentLocationModal"
                                    >
                                      Share My Location
                                    </button>
                                  </div>
                                </span>
                              )
                          }
                      </div>
                    </div>
                  ))}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <ClaimStatus />
        </div>
        <div className="row grey-cell">{fitment}</div>
        <TimeUpdateModal UpdateTime={this.UpdateTime} />
        <CurrentLocationModal SendLocation={this.SendLocation} />
        <SweetAlert
          show={this.state.showAlert}
          title="Confirm Details"
          html
          text="Fitment address successfully confirmed. Please confirm vehicle."
          onConfirm={() => {
            this.setState({
              ...this.state,
              showAlert: false,
            });
          }}
        />
      </div>
    );
  }
}

Fitment.propTypes = {
  confirmAddress: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  confirmAddress: (confirmed) => {
    dispatch({ type: 'CONFIRM_ADDRESS', value: confirmed });
  },
});

export default connect(null, mapDispatchToProps)(Fitment);
