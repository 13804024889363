import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ClaimStatusIndicator from '../ClaimStatusIndicator/ClaimStatusIndicator';

import './ClaimStatus.css';

const ClaimStatus = (props) => {
  let drawStatus = null;
  if (props.currentStatus === 6) {
    drawStatus = (
      <h4 className="col-xs-12 text-justify text-uppercase">Declined</h4>
    );
  } else if (props.currentStatus === 7) {
    drawStatus = (
      <h4 className="col-xs-12 text-justify text-uppercase">Cancelled</h4>
    );
  } else {
    drawStatus = (
      <div>
        <ClaimStatusIndicator
          claimStatusName="Claim Created"
          isCompleted={props.currentStatus > 0}
          helpText={<p>Your claim has been loaded on our system.</p>}
        />
        <ClaimStatusIndicator
          claimStatusName="Insurer Approved"
          isCompleted={props.currentStatus > 1}
          helpText={
              props.currentStatus > 1 ? (
                <p>
                  Approved. Our branch will be in contact shortly to schedule a
                  fitment.
                </p>
              ) : (
                <p>We are waiting approval from your insurance company.</p>
              )
            }
        />
        <ClaimStatusIndicator
          claimStatusName="Service Booked"
          isCompleted={props.currentStatus > 2}
          helpText={
              props.currentStatus > 2 ? (
                <p>
                  Your service has been booked for
                  {' '}
                  {props.fitmentDate}
                  .
                </p>
              ) : (
                <p>
                  Once approval is received we will call you to schedule the
                  service.
                </p>
              )
            }
        />
        <ClaimStatusIndicator
          claimStatusName="Service Today"
          isCompleted={props.currentStatus > 3}
          helpText={
              props.currentStatus > 3 ? (
                <p>This will highlight on the day your service is scheduled.</p>
              ) : (
                <p>
                  Your fitment is scheduled for today. Please be avialble at the
                  agreed time.
                </p>
              )
            }
        />
        <ClaimStatusIndicator
          claimStatusName="Claim Complete"
          isCompleted={props.currentStatus > 4}
          helpText={
              props.currentStatus > 4 ? (
                <p>
                  This will highlight once the service has been successfully
                  completed.
                </p>
              ) : (
                <p>
                  The fitment has been completed successfully. Please take a
                  moment to rate our service to you.
                </p>
              )
            }
        />
      </div>
    );
  }

  return (
    <div id="claim-status" className="col-xs-12">
      <h4>Claim Status</h4>
      <div className="row center">{drawStatus}</div>
    </div>
  );
};

ClaimStatus.propTypes = {
  currentStatus: PropTypes.number.isRequired,
  fitmentDate: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  currentStatus: state.currentStatus,
  fitmentDate: state.fitmentDate,
});

export default connect(mapStateToProps)(ClaimStatus);
