import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TopNavButton from '../TopNavButton/TopNavButton';

import './TopNav.css';

class TopNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: '',
    };

    this.setCurrentTab = this.setCurrentTab.bind(this);
  }

  componentDidMount() {
    this.props.addSteps([
      {
        title: 'Claim',
        text: 'A brief claim overview, including the Autoboys, some personal information, and what kind of work will be done.',
        selector: '#ClaimNav',
        position: 'bottom',
        style: {
          mainColor: '#1c3c6c',
          beacon: {
            inner: '#1c3c6c',
            outer: '#1c3c6c',
          },
        },
      },
      {
        title: 'Vehicle',
        text: 'From here, you can view and confirm your vehicle information.',
        selector: '#VehicleNav',
        position: 'bottom',
        style: {
          mainColor: '#1c3c6c',
          beacon: {
            inner: '#1c3c6c',
            outer: '#1c3c6c',
          },
        },
      },
      {
        title: 'Fitment',
        text: 'Your fitment details, including the ability to confirm the fitment address.',
        selector: '#FitmentNav',
        position: 'bottom',
        style: {
          mainColor: '#1c3c6c',
          beacon: {
            inner: '#fcb823',
            outer: '#fcb823',
          },
        },
      },
    ]);
  }

  setCurrentTab(tab) {
    this.setState({
      ...this.state,
      currentTab: tab,
    });
  }

  render() {
    return (
      <div className="row" id="top-nav">
        <TopNavButton
          id="ClaimNav"
          buttonText="Claim"
          linkto=""
          confirmed
          active={this.state.currentTab === ''}
          setCurrentTab={this.setCurrentTab}
        />
        <TopNavButton
          id="VehicleNav"
          buttonText="Vehicle"
          linkto="vehicle"
          confirmed={this.props.vehicleConfirmed}
          active={this.state.currentTab === 'vehicle'}
          setCurrentTab={this.setCurrentTab}
        />
        <TopNavButton
          id="FitmentNav"
          buttonText="Fitment"
          linkto="fitment"
          confirmed={this.props.addressConfirmed}
          active={this.state.currentTab === 'fitment'}
          setCurrentTab={this.setCurrentTab}
        />
      </div>
    );
  }
}

TopNav.propTypes = {
  addSteps: PropTypes.func.isRequired,
  vehicleConfirmed: PropTypes.bool.isRequired,
  addressConfirmed: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  vehicleConfirmed: state.vehicleConfirmed,
  addressConfirmed: state.addressConfirmed,
});

export default connect(mapStateToProps)(TopNav);
