import React from 'react';
import toastr from 'toastr';
import PropTypes from 'prop-types';

import Fitment from '../../Logic/Fitment';

import './FitmentOptions.css';

class FitmentOptions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: undefined,
      Lat: props.Lat,
      Lng: props.Lng,
    };

    this.fetchOptions = this.fetchOptions.bind(this);
  }

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate() {
    if (this.state.Lat !== this.props.Lat || this.state.Lng !== this.props.Lng) {
      this.fetchOptions();
    }
  }

  fetchOptions() {
    Fitment.FetchFitmentOptions(this.props.Lat, this.props.Lng, (e, r) => {
      if (e) toastr.error(`Something went wrong and we were unable to fetch the fitment options. Error: ${e.message}`, 'Oops');
      this.setState({
        options: r,
        Lat: this.props.Lat,
        Lng: this.props.Lng,
      });
    });
  }

  render() {
    return (
      <div>
        <div className="row" id="fitment-options">
          <div className="col-xs-12">
            {/* <h4 id="fitment-options-header">Possible Fitment Options</h4> */}
            <div className="col-xs-12">
              {this.state.options
                ? (
                  <table id="fitment-options-table">
                    <tbody>
                      {this.state.options.map((o) => (
                        <tr key={o.Day}>
                          <th>
                            {o.Date}
                            ,
                            {' '}
                            {o.DayName}
                          </th>
                          {o.Times.map((t) => (
                            <td key={t.Time}>
                              <button
                                className="btn btn-primary btn-lg"
                                type="button"
                                disabled={
                                  o.Date === this.props.Fitment.Date
                                  && t.Time === this.props.Fitment.Time
                                }
                                onClick={
                                () => this
                                  .props
                                  .selectChoice({
                                    Date: o.Date,
                                    Time: t.Time,
                                    TeamId: t.TeamIds[0],
                                    OptimizationModifier: t.Modifier,
                                  })
                              }
                              >
                                {t.Time}
                              </button>
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
                : (<h5>Loading...</h5>)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FitmentOptions.propTypes = {
  Lat: PropTypes.number.isRequired,
  Lng: PropTypes.number.isRequired,
  Fitment: PropTypes.shape.isRequired,
  selectChoice: PropTypes.func.isRequired,
};

export default FitmentOptions;
