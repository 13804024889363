import React from 'react';
import PropTypes from 'prop-types';
import 'date-tostring';

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      User: this.props.User,
      Role: this.props.Role,
      Outlet: this.props.Outlet,
      CreateDate: this.props.CreateDate,
      Message: this.props.Message,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      User: newProps.User,
      Role: newProps.Role,
      Outlet: newProps.Outlet,
      CreateDate: newProps.CreateDate,
      Message: newProps.Message,
    });
  }

  render() {
    return (
      <div className={`comment row ${this.props.Index % 2 === 0 ? 'grey-cell' : ''}`}>
        <div className="col-xs-12">
          <p className="comment-header">
            {this.state.User}
            {' '}
            {this.state.Role.length > 0 ? `(${this.state.Role})` : ''}
            {' '}
            {this.state.Outlet}
            {' '}
            <br />
            <small>{(new Date(this.state.CreateDate)).toString('yyyy/MM/dd HH:mm:ss')}</small>
          </p>
          <p>{this.state.Message}</p>
        </div>
      </div>
    );
  }
}

Comment.propTypes = {
  User: PropTypes.string,
  Role: PropTypes.string,
  Outlet: PropTypes.string,
  CreateDate: PropTypes.string,
  Message: PropTypes.string,
  Index: PropTypes.number,
};

Comment.defaultProps = {
  User: '',
  Role: '',
  Outlet: '',
  CreateDate: null,
  Message: '',
  Index: 0,
};

export default Comment;
