import React from 'react';
import toastr from 'toastr';
import async from 'async';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import SweetAlert from 'sweetalert-react';
import { connect } from 'react-redux';

import Claim from '../../Logic/Claim';
import VehicleObj from '../../Logic/Vehicle';
import VehicleButton from './VehicleButton/VehicleButton';
import ClaimStatus from '../common/ClaimStatus/ClaimStatus';

import './Vehicle.css';
import TakePhotoButton from './TakePhotoButton/TakePhotoButton';

class Vehicle extends React.Component {
  constructor(props) {
    super(props);

    this.getVehicle = this.getVehicle.bind(this);
    this.vehicleConfirmed = this.vehicleConfirmed.bind(this);
    this.confirmVehicle = this.confirmVehicle.bind(this);
    this.savePhoto = this.savePhoto.bind(this);

    this.state = {
      vehicle: {},
      contactNumber: '',
      fitmentConfirmed: false,
      showAlert: false,
      showImageAlert: false,
    };
  }

  async componentDidMount() {
    await this.getVehicle();
  }

  async getVehicle() {
    try {
      const vehicle = await Claim.FetchVehicleInformation();
      this.setState({
        ...this.state,
        vehicle,
        showImageAlert: !vehicle.FrontViewImage || !vehicle.WindscreenImage,
      });
    } catch (error) {
      toastr.error(`Something went wrong and we were unable to fetch the vehicle's details. Error: ${error.message}`, 'Oops');
    }
  }

  confirmVehicle() {
    async.waterfall([
      VehicleObj.confirmVehicle,
    ], (error) => {
      if (error) {
        toastr.message(`Something went wrong and we were unable to confirm the vehicle's details. Error: ${error}`, 'Oops');
      } else {
        const newVehicle = { ...this.state.vehicle };
        newVehicle.Confirmed = true;
        this.props.confirmVehicle(true);
        this.setState({
          ...this.state,
          vehicle: newVehicle,
          showAlert: true,
        });
      }
    });
  }

  async savePhoto(image, imageName) {
    try {
      const success = await VehicleObj.saveVehicleImage(image, imageName);
      toastr.success(success.message, 'Photo successfully updated');
      if (success) {
        const vehicle = await Claim.FetchVehicleInformation();
        this.setState({
          ...this.state,
          vehicle,
        }, () => {
          if (this.state.vehicle.FrontViewImage && this.state.vehicle.WindscreenImage && this.state.vehicle.LicenceDisc) { toastr.success(success.message, 'All Vehicle Photos successfully updated'); }
        });
        if (imageName === 'LicenceDisc') {
          document.getElementById('ScanResults').textContent = '';
        }
      }
    } catch (error) {
      toastr.error(error.message, 'Failed to save photo');
    }
  }

  vehicleConfirmed() {
    let ScanMessage;
    if (this.state.vehicle.LicenceDiscScanned) {
      ScanMessage = (
        <div id="ScanResults" className="col-xs-12 confirmed">
          Licence Disc Scanned Successfully.
          The results can be viewed in the Vehicle Details or the Activity Log
        </div>
      );
    } else {
      ScanMessage = (
        <div id="ScanResults" className="col-xs-12 not-confirmed">
          Licence Not Scanned.
          Please Upload a Clear Image of the Vehicles Licence Disc
        </div>
      );
    }
    return (
      <Route
        render={({ history }) => (
          <div id="make-model-confirm">
            <div className="row" id="make-model-confirm-header">
              <span className="col-xs-6 text-left">Vehicle Details</span>
              <span className="col-xs-6 text-left" id="make-model-confirm-status">
                <div className="row">
                  <div className="col-xs-12 not-confirmed">
                    <FontAwesome name={!this.state.vehicle.Confirmed ? 'exclamation' : 'check'} />
                    {!this.state.vehicle.Confirmed ? ' Not ' : ' '}
                    Confirmed
                  </div>
                </div>
              </span>
            </div>
            {this.state.vehicle !== undefined && this.state.vehicle.Make !== undefined && this.state.vehicle.Make.includes() !== undefined && !this.state.vehicle.Make.includes('AUTOMATION') ? (
              <>
                <div className="text-left">
                  <div className="row">
                    <span className="col-xs-4"><b>VIN:</b></span>
                    <span className="col-xs-8">{this.state.vehicle.VinNo}</span>
                  </div>
                  <div className="row">
                    <span className="col-xs-4"><b>Make:</b></span>
                    <span className="col-xs-8">{this.state.vehicle.Make}</span>
                  </div>
                  <div className="row">
                    <span className="col-xs-4"><b>Range:</b></span>
                    <span className="col-xs-8">{this.state.vehicle.Range}</span>
                  </div>
                  <div className="row">
                    <span className="col-xs-4"><b>Sub Range:</b></span>
                    <span className="col-xs-8">{this.state.vehicle.SubRange}</span>
                  </div>
                  <div className="row">
                    <span className="col-xs-4"><b>Description:</b></span>
                    <span className="col-xs-8">{this.state.vehicle.Description}</span>
                  </div>
                </div>
                <div className="row" style={{ marginTop: '10px' }}>
                  {!this.state.vehicle.Confirmed ? (
                    <div className="col-xs-6">
                      <VehicleButton text="Confirm Vehicle" onClick={this.confirmVehicle} />
                    </div>
                  ) : (
                    <div className="col-xs-3" />
                  )}
                  <div className="col-xs-6">
                    <VehicleButton text="Update Vehicle" onClick={() => { history.push('/makecall'); }} />
                  </div>
                  {!this.state.vehicle.Confirmed ? null : (
                    <div className="col-xs-3" />
                  )}
                </div>
              </>
            ) : null}
            <div className="row" style={{ marginTop: '10px' }}>
              <TakePhotoButton
                Image={this.state.vehicle.FrontViewImage}
                ImageTitle="Front View"
                ImageName="FrontView"
                Action={this.savePhoto}
              />
              <TakePhotoButton
                Image={this.state.vehicle.WindscreenImage}
                ImageTitle="Damage"
                ImageName="Damage"
                Action={this.savePhoto}
              />
            </div>
            <div className="row" style={{ marginTop: '10px' }}>
              <TakePhotoButton
                Image={this.state.vehicle.LicenceDisc}
                ImageTitle="Licence Disc"
                ImageName="LicenceDisc"
                Action={this.savePhoto}
              />
              { /* TODO:  Remove 'hidden' class once Barcode Scanner is working */ }
              <div className="col-xs-6 text-center hidden">
                {ScanMessage}
              </div>
            </div>
          </div>
        )}
      />
    );
  }

  render() {
    return (
      <div id="Vehicle">
        <div className="row">
          <ClaimStatus
            currentStatus={this.state.claimStatus}
            fitmentDate={this.state.fitmentDate}
          />
        </div>
        <div className="row grey-cell">
          <div className="col-xs-12">
            {this.vehicleConfirmed()}
          </div>
        </div>
        <SweetAlert
          show={this.state.showAlert}
          title="Confirm Details"
          html
          text="Vehicle successfully confirmed. Please confirm fitment address."
          onConfirm={() => {
            this.setState({
              ...this.state,
              showAlert: false,
            });
          }}
        />
        <SweetAlert
          show={this.state.showImageAlert}
          title="Vehicle Photos"
          html
          text="Upload photos of the damage and front of your car
                now to help us get the right glass to you.."
          onConfirm={() => {
            this.setState({
              ...this.state,
              showImageAlert: false,
            });
          }}
        />
      </div>
    );
  }
}

Vehicle.propTypes = {
  confirmVehicle: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  confirmVehicle: (confirmed) => {
    dispatch({ type: 'CONFIRM_VEHICLE', value: confirmed });
  },
});

export default connect(null, mapDispatchToProps)(Vehicle);
