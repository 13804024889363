import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import { connect } from 'react-redux';
import SweetAlert from 'sweetalert-react';

import Logo from '../../assets/images/logo.png';
import Call from '../../assets/images/call-agent.png';
import Database from '../../Logic/Database';
import Claim from '../../Logic/Claim';
import './VerifyVehicleReg.css';

class VerifyVehicleReg extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enteredReg: '',
      showHelp: false,
      vehicleReg: [],
      requiredLetters: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.CustAppId) {
      try {
        const vehicleReg = await Claim.FetchVehicleReg(this.props.CustAppId);

        const vehicleRegArray = vehicleReg.split('');

        const requiredLetters = [0, 2, 3, 6];

        this.setState({
          ...this.state,
          vehicleReg: vehicleRegArray,
          requiredLetters,
        });
      } catch (error) {
        toastr.error('Failed to initialise security checks.');
      }
    }
  }

  verifyVehicleReg = async () => {
    try {
      const state = {
        ...this.state,
      };
      const vehicleRegVerified = await Claim
        .VerifyVehicleReg(this.props.CustAppId, state.vehicleReg.join(''));

      if (vehicleRegVerified) {
        await Database.VerifyVehicleReg(vehicleRegVerified);

        const claimData = await Claim.FetchClaimData();
        await Database.WriteInitialData(claimData);
        if (window.location.href.includes('additionalinfo')) {
          window.location.href = `/additionalinfo?${this.props.CustAppId}`;
        } else {
          window.location.href = `/?${this.props.CustAppId}`;
        }
      } else {
        this.setState({
          ...this.state,
          showHelp: true,
        });
        throw new Error('Incorrect vehicle registration number supplied.');
      }
    } catch (error) {
      toastr.error(
        error.message,
        'Failed to verify vehicle registration number.',
      );
    }
  }

  render = () => (
    <div id="verify-reg-form" className="col-xs-12">
      <SweetAlert
        show={!this.props.CustAppId || this.props.CustAppId.length < 16}
        title="Claim Reference Missing"
        text="Link expired. Kindly request your service consultant to resend you a link."
      />
      <div className="col-xs-12" id="logo-container">
        <img
          id="company-logo"
          alt="Autoboys"
          src={Logo}
          className="center-block"
        />
      </div>
      <div className="col-xs-12">
        <p>
          Welcome to the Autoboys customer application. Please verify your
          vehicle registration number to start your claim.
        </p>
      </div>
      <div className="form-group col-xs-12">
        <label>Vehicle Registration</label>
        <div>
          {this.state.vehicleReg.map((item, index) => {
            if (this.state.requiredLetters.indexOf(index) > -1) {
              return (
                <input
                  type="text"
                  onChange={(event) => {
                    this.setState({
                      ...this.state,
                      [`txt${index}`]: event.target.value,
                    });
                  }}
                  value={this.state[`txt${index}`] || ''}
                  className="form-control reg-verify"
                  maxLength="1"
                />
              );
            }
            return <input type="text" value={item} readOnly size="1" className="form-control reg-verify" />;
          })}
        </div>
      </div>
      <div className="form-group col-xs-12">
        <button
          className="btn btn-success btn-lg col-xs-12"
          onClick={this.verifyVehicleReg}
        >
          Verify
        </button>
      </div>
      <div
        className={this.state.showHelp ? 'col-xs-12 text-center' : 'col-xs-12 text-center hidden'}
        onClick={() => {
          Claim.PingContactUs();
          document.location.href = 'tel:0106008888';
        }}
        onKeyDown={() => {
          Claim.PingContactUs();
          document.location.href = 'tel:0106008888';
        }}
        role="button"
        tabIndex={0}
      >
        <img src={Call} className="get-help" alt="Get Help" />
        <br />
        Get Help
        <br />
        <b>0106008888</b>
      </div>
    </div>
  )
}

VerifyVehicleReg.propTypes = {
  CustAppId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  CustAppId: state.CustAppId,
});

export default connect(mapStateToProps)(VerifyVehicleReg);
