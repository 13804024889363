import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import '../../../../node_modules/font-awesome/css/font-awesome.css';
import './HeaderButton.css';

class HeaderButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonText: this.props.buttonText,
      buttonImage: this.props.iconClass,
      linkto: this.props.linkto,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      buttonText: newProps.buttonText,
      buttonImage: newProps.iconClass,
      linkto: newProps.linkto,
    });
  }

  render() {
    return (
      <div id={this.props.id} className="header-button col-xs-6 center">
        <Route
          render={({ history }) => (
            <div>
              <div
                role="button"
                onClick={() => {
                  if (this.state.linkto !== 'makecall') {
                    history.push(`/${this.state.linkto}`);
                  } else {
                    this.props.callFunc();
                  }
                }}
                onKeyPress={() => {
                  history.push(`/${this.state.linkto}`);
                }}
                tabIndex={0}
              >
                <img
                  className="footer-icon"
                  alt={this.state.buttonText}
                  src={this.state.buttonImage}
                />
              </div>
              <div className="small footer-label">{this.state.buttonText}</div>
            </div>
          )}
        />
      </div>
    );
  }
}

HeaderButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  iconClass: PropTypes.string.isRequired,
  linkto: PropTypes.string.isRequired,
  callFunc: PropTypes.func,
  id: PropTypes.string.isRequired,
};

HeaderButton.defaultProps = {
  callFunc: () => false,
};

export default HeaderButton;
