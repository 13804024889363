/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import './TakePhotoButton.css';
// import placeholder from '../../../assets/images/placeholder.jpg';

import TakePhotoModal from '../TakePhotoModal/TakePhotoModal';

class TakePhotoButton extends React.Component {
  constructor(props) {
    super(props);
    this.showPhotoModal = this.showPhotoModal.bind(this);
    this.hidePhotoModal = this.hidePhotoModal.bind(this);

    this.state = {
      Image: '',
      showModal: false,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    try {
      if (newProps.Image != null) {
        this.setState({
          Image: newProps.Image,
        });
      }
    } catch (error) {
      toastr.error(error.message);
    }
  }

  showPhotoModal() {
    this.setState({
      showModal: true,
    });
  }

  hidePhotoModal() {
    this.setState({
      showModal: false,
    });
  }

  render() {
    return (
      <div>
        <div className="col-xs-12 col-lg-6 text-center">
          <div
            className="col-xs-12 text-center img-card"
            style={{ float: 'none' }}
          >
            <div className="text-center img-title">{this.props.ImageTitle}</div>
            &nbsp;
            {
            this.state.Image
              ? (
                <img
            // `https://drive.google.com/file/d/${this.state.Image}/view`
                  src={this.state.Image !== '' ? this.state.Image : null}
                  className="photo take-photo-button rounded-corners"
                  alt=""
                />
              ) : null
             }
            <button
              className="newBtn btn-success col-xs-12 updateSteps"
              style={{ marginTop: '5px' }}
              onClick={this.showPhotoModal}
            />
          </div>
        </div>
        {this.state.showModal
        && (
        <TakePhotoModal
          ImageName={this.props.ImageName}
          Hide={this.hidePhotoModal}
          Action={(image, imageName) => {
            this.setState({
              Image: image,
            });
            this.props.Action(image, imageName);
          }}
        />
        )}
      </div>
    );
  }
}

TakePhotoButton.propTypes = {
  ImageTitle: PropTypes.string.isRequired,
  ImageName: PropTypes.string.isRequired,
  Action: PropTypes.func.isRequired,
};

export default TakePhotoButton;
