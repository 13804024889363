import React from 'react';
import { Route } from 'react-router-dom';
import toastr from 'toastr';

import Database from '../../../Logic/Database';
import Claim from '../../../Logic/Claim';

import Yes from '../../../assets/images/home-call-button.png';
import No from '../../../assets/images/cancel-call.png';

class MakeCall extends React.Component {
  constructor(props) {
    super(props);

    this.getAgentNumber = this.getAgentNumber.bind(this);

    this.state = {
      contactNumber: '',
    };
  }

  componentDidMount() {
    this.getAgentNumber();
  }

  getAgentNumber() {
    Database.FetchOutletInformation()
      .then((outlet) => {
        this.setState({
          ...this.state,
          contactNumber: outlet.ContactNumber,
        });
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  }

  render() {
    return (
      <div className="col-xs-12 text-left">
        <h4>CALL MY AGENT TO UPDATE MY INFORMATION</h4>
        <p>
          You are about to make a call to Otsile Molusi at our Autoboys branch
          in Johannesburg regarding the details of your claim.
        </p>
        <p><small>*Standard network Rates will apply.</small></p>
        <Route
          render={({ history }) => (
            <div className="row">
              <div
                className="col-xs-4 col-xs-offset-2"
                role="button"
                tabIndex={0}
                onClick={() => {
                  Claim.PingContactUs();
                  window.location.href = `tel:${this.state.contactNumber}`;
                }}
                onKeyPress={() => {
                  Claim.PingContactUs();
                  window.location.href = `tel:${this.state.contactNumber}`;
                }}
              >
                <img alt="Yes" src={Yes} style={{ width: '100%', maxWidth: '100px' }} />
              </div>
              <div
                className="col-xs-4"
                role="button"
                tabIndex={0}
                onClick={() => { history.goBack(); }}
                onKeyPress={() => { history.goBack(); }}
              >
                <img alt="No" src={No} style={{ width: '100%', maxWidth: '100px' }} />
              </div>
            </div>
          )}
        />
      </div>
    );
  }
}

export default MakeCall;
