import React from 'react';
import PropTypes from 'prop-types';

const VehicleButton = (props) => (
  <button className="btn btn-success col-xs-12" onClick={props.onClick}>{props.text}</button>
);

VehicleButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default VehicleButton;
