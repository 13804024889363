import React from 'react';
import Claim from '../../../Logic/Claim';

import ClaimStatus from '../../common/ClaimStatus/ClaimStatus';

class RequestContact extends React.Component {
  constructor(props) {
    super(props);

    this.sendRequest = this.sendRequest.bind(this);
    this.state = {
      requestSent: false,
      hasError: false,
    };

    this.sendRequest();
  }

  sendRequest() {
    Claim.SendContactRequest()
      .then(() => {
        this.setState({
          requestSent: true,
          hasError: false,
        });
      })
      .catch(() => {
        this.setState({
          requestSent: false,
          hasError: true,
        });
      });
  }

  render() {
    if (this.state.requestSent === true) {
      return (
        <div>
          <ClaimStatus />
          <div className="col-xs-12" id="request-contact">
            <h4 className="text-left">The agent assigned to your claim has been Notified</h4>
            <div className="row">
              <div className="col-xs-12 small text-left">
                <p>Your agent will be in touch shortly to discuss your complaint</p>
              </div>
            </div>
          </div>
        </div>
      );
    } if (this.state.hasError === true) {
      return (
        <div>
          <ClaimStatus
            currentStatus={this.state.claimStatus}
          />
          <div className="col-xs-12" id="request-contact">
            <h4 className="text-left">Oops...</h4>
            <div className="row">
              <div className="col-xs-8 small text-left">
                Something went wrong, and we were not able to contact you assigned agent.
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row">
          <ClaimStatus />
        </div>
        <div className="col-xs-12" id="request-contact">
          <h4 className="text-left">Please wait...</h4>
          <div className="row">
            <div className="col-xs-8 small text-left">
              We are sending the notification now.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestContact;
