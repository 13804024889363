/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import toastr from 'toastr';
import axios from 'axios-es6';
import $ from 'jquery';
// import Collapsible from 'react-collapsible';
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from 'react-accessible-accordion';
import SweetAlert from 'sweetalert-react';
import Database from '../../Logic/Database';
import CurrentLocationModal from '../Fitment/CurrentLocationModal';
import FitmentOptions from '../FitmentOptions';
import FitmentLogic from '../../Logic/Fitment';
import ClaimLogic from '../../Logic/Claim';
import VehicleLogic from '../../Logic/Vehicle';
import global from '../../global';
import TakePhotoButton from '../Vehicle/TakePhotoButton/TakePhotoButton';
import 'react-accessible-accordion/dist/fancy-example.css';
import './AdditionalInfo.css';

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        CustAppId: window.location.search.substr(1, window.location.search.length - 1),
        VehicleMake: '',
        VehicleModel: '',
        VehicleColour: '',
        VehicleRegistration: '',
        NewAddress: '',
        Lat: 0,
        Lng: 0,
        Fitment: {},
        FrontViewImage: undefined,
        WindscreenImage: undefined,
        LicenceDisc: undefined,
        LicenceDiscScanned: false,
      },
      sweetAlertMsg: '',
      sweetAlertTitle: '',
      sweetAlertAction: undefined,
      setLocation: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.SendLocation = this.SendLocation.bind(this);
    this.savePhoto = this.savePhoto.bind(this);
  }

  componentDidMount() {
    $('#welcomeModal').addClass('in').css('display', 'block');
    $('#footer').addClass('in').css('display', 'block');
    Database.FetchAllData()
      .then((data) => {
        this.setState({
          value: {
            CustAppId: this.state.value.CustAppId,
            VehicleMake: data.Vehicle.Make.includes('AUTOMATION') ? '' : data.Vehicle.Make,
            VehicleModel: data.Vehicle.Range.includes('AUTOMATION') ? '' : data.Vehicle.Range,
            VehicleColour: '',
            VehicleRegistration: data.Vehicle.RegNo ?? '',
            NewAddress: data.Fitment.Address,
            Lat: Number(data.Fitment.Latitude),
            Lng: Number(data.Fitment.Longetude),
            FrontViewImage: data.Vehicle.FrontViewImage,
            WindscreenImage: data.Vehicle.WindscreenImage,
            LicenceDisc: data.Vehicle.LicenceDisc,
            LicenceDiscScanned: data.Vehicle.LicenceDiscScanned,
          },
        });
      });
  }

  handleChange(event) {
    const { value } = this.state;
    value[event.target.name] = event.target.value;
    this.setState({ value });
  }

  handleSubmit(event) {
    try {
      console.log(this.state);
      const success = [];
      success.push(FitmentLogic.UpdateLocation(
        this.state.value.NewAddress,
        this.state.value.Lat,
        this.state.value.Lng,
      ));
      success.push(FitmentLogic.UpdateFitmentAddress(this.state.value.NewAddress));
      if (!success[0] || !success[1]) {
        throw new Error('Unable to update fitment location.');
      }
      event.preventDefault();
      axios.post(`${global.BASE_URL}CustomerApp/AdditionalInfo`, this.state.value);
      this.setState({
        sweetAlertTitle: 'Information Submitted Successfully',
        sweetAlertMsg: 'Thank you for submitting your information. We will confirm your selected fitment date via sms soon based on the availability of the glass. Kindly wait for the sms confirmation.',
        sweetAlertAction: () => window.location.replace('http://www.autoboys.co.za'),
      });
    } catch {
      this.setState({
        sweetAlertTitle: 'Failed',
        sweetAlertMsg: 'Please ensure you have a stable internet connection, and contact your service consultant if the issue persists.',
        sweetAlertAction: undefined,
      });
    }
  }

  async savePhoto(image, imageName) {
    try {
      const success = await VehicleLogic.saveVehicleImage(image, imageName);
      toastr.success(success.message, 'Photo successfully updated');
      if (success) {
        const vehicle = await ClaimLogic.FetchVehicleInformation();
        const { value } = this.state;
        value.FrontViewImage = vehicle.FrontViewImage;
        value.WindscreenImage = vehicle.WindscreenImage;
        value.LicenceDisc = vehicle.LicenceDisc;
        value.LicenceDiscScanned = vehicle.LicenceDiscScanned;
        this.setState({ value });
        if (this.state.value.FrontViewImage && this.state.value.WindscreenImage && this.state.value.LicenceDisc) {
          toastr.success(success.message, 'All Vehicle Photos successfully updated');
        }

        if (imageName === 'LicenceDisc') {
          document.getElementById('ScanResults').textContent = '';
        }
      }
    } catch (error) {
      toastr.error(error.message, 'Failed to save photo');
    }
  }

  async SendLocation(newAddress, Lat, Lng) {
    try {
      const { value } = this.state;
      value.NewAddress = newAddress;
      value.Lat = Lat;
      value.Lng = Lng;
      this.setState({ value });
      this.setState({ setLocation: true });
    } catch (error) {
      toastr.error(error.message, 'Failed to update fitment location.');
    }
  }

  redirectToAutoboys() {
    $('#welcomeModal').addClass('in').css('display', 'none');
    window.location.replace('http://www.autoboys.co.za');
  }

  render() {
    let ScanMessage;
    if (this.state.value.LicenceDiscScanned) {
      ScanMessage = (
        <span id="ScanResults" className=" confirmed">
          Licence Disc Scanned Successfully.
          The results can be viewed in the Vehicle Details or the Activity Log
        </span>
      );
    } else {
      ScanMessage = (
        <span id="ScanResults" className=" not-confirmed">
          {/* Licence Not Scanned.
          Please Upload a Clear Image of the Vehicles Licence Disc */}
        </span>
      );
    }
    return (
      <div>
        <SweetAlert
          show={this.state.sweetAlertTitle && this.state.sweetAlertMsg}
          title={this.state.sweetAlertTitle}
          text={this.state.sweetAlertMsg}
          onConfirm={() => {
            this.setState({
              sweetAlertTitle: '',
              sweetAlertMsg: '',
            }, () => {
              if (this.state.sweetAlertAction) { this.state.sweetAlertAction(); }
            });
          }}
        />

        {/* Modal Popup */}
        <div className="modal fade" id="welcomeModal" style={{ marginTop: '100px' }}>
          <div className="modal-dialog modal-confirm">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Welcome to Autoboys Ejourney</h4>
              </div>
              <div className="modal-body">
                Please follow the prompt on the screen to confirm your vehicle details and fitment information.
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-info"
                  data-dismiss="modal"
                  onClick={() => this.redirectToAutoboys()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => {
                    $('#welcomeModal').addClass('in').css('display', 'none');
                  }}
                  data-dismiss="modal"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>

        &nbsp;
        {/* Vehicle Photos */}
        <div className="row grey-cell">
          <div
            className="header panel-group"
            style={{ fontSize: '15px' }}
            type="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapse"
          >
            Vehicle & Licence Disc Photos  (Step-1)
          </div>
          <div className="col-xs-12 panel-collapse collapse in" id="collapse">
            <div className="row text-center" style={{ marginTop: '10px' }}>
              <TakePhotoButton
                Image={this.state.value.FrontViewImage}
                ImageTitle="Front view of vehicle"
                ImageName="FrontView"
                Action={this.savePhoto}
              />
              <TakePhotoButton
                Image={this.state.value.WindscreenImage}
                ImageTitle="Damages of glass"
                ImageName="Damage"
                Action={this.savePhoto}
              />
            </div>
            <div className="row text-center" style={{ marginTop: '10px' }}>
              <TakePhotoButton
                Image={this.state.value.LicenceDisc}
                ImageTitle="Licence Disc"
                ImageName="LicenceDisc"
                Action={this.savePhoto}
              />
              { /* TODO:  Remove 'hidden' class once Barcode Scanner is working */}
            </div>
            <div className="scan-messsage">
              {ScanMessage}
            </div>
          </div>
        </div>
        &nbsp;

        {/* Vehicle Details */}
        {/* <div className="row grey-cell">
          <div className="header">
            Vehicle Details (Step-4)
            <div className="col-xs-12">
              &nbsp;
              <table style={{ marginLeft: '50px' }}>
                <tr>
                  <td>
                    <label>
                      Vehicle Make:
                    </label>
                  </td>
                  <td>
                    <input type="text" style={{ width: '500px' }} value={this.state.value.VehicleMake} name="VehicleMake" onChange={this.handleChange} />
                  </td>
                </tr>
                &nbsp;
                <tr>
                  <td>
                    <label>
                      Vehicle Model:
                    </label>
                  </td>
                  <td>
                    <input type="text" style={{ width: '500px' }} value={this.state.value.VehicleModel} name="VehicleModel" onChange={this.handleChange} />
                  </td>
                </tr>
                &nbsp;
                <tr>
                  <td>
                    <label>
                      Vehicle Registration:
                    </label>
                  </td>
                  <td>
                    <input type="text" style={{ width: '500px' }} value={this.state.value.VehicleRegistration} name="VehicleRegistration" onChange={this.handleChange} />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        &nbsp; */}

        {/* Fitment Location */}
        <div className="row grey-cell">
          <div
            className="header panel-group"
            style={{ fontSize: '15px' }}
            type="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseOne"
          >
            Set Fitment Location (Step-2)
          </div>
          <div className="col-xs-12">
            <CurrentLocationModal SendLocation={this.SendLocation} />
            <div className={this.state.setLocation ? 'panel-collapse collapse ' : 'panel-collapse collapse'} id="collapseOne">
              <button
                className="newBtn2 btn-success col-xs-12 "
                data-toggle="modal"
                data-target="#currentLocationModal"
                type="button"
              >
                Set Fitment Location
              </button>
            </div>
          </div>
        </div>
        &nbsp;

        {/* Fitment Options */}
        {this.state.value.Lat && this.state.value.Lng ? (
          <div className="row grey-cell" id="fitmentOptions">
            <div
              className="header panel-group"
              style={{ fontSize: '15px' }}
              type="button"
              data-toggle="collapse"
              data-parent="#accordion"
              href="#collapseTwo"
            >
              Possible Fitment Options (Step-3)
            </div>
            <div className="col-xs-12">
              <div className={this.state.setLocation ? 'panel-collapse collapse in' : 'panel-collapse collapse'} id="collapseTwo">
                {
                  this.state.setLocation ? (
                    <FitmentOptions
                      className="newBtn2 btn-success col-xs-12 "
                      Lat={this.state.value.Lat}
                      Lng={this.state.value.Lng}
                      Fitment={this.state.value.Fitment ?? {}}
                      selectChoice={(f) => this.setState({ value: { ...this.state.value, Fitment: f } })}
                    />
                  ) : (
                    <div className="location-error">
                      Please set location
                    </div>
                  )
               }
              </div>
            </div>
          </div>
        ) : null}
        &nbsp;

        {/* Verify Information */}
        <div className="row grey-cell ">
          <div
            className="header panel-group"
            style={{ fontSize: '15px' }}
            type="button"
            data-toggle="collapse"
            data-parent="#accordion"
            href="#collapseThree"
          >
            Verify the Information (Step-4)
          </div>
          <div className="col-xs-12 text-center">
            <div className="panel-collapse collapse " id="collapseThree">
              <button className="newBtn2 btn-success col-xs-12" type="submit" value="Submit" onClick={this.handleSubmit}>Information is Correct (Verify)</button>
              <CurrentLocationModal SendLocation={this.SendLocation} />
            </div>
          </div>
        </div>
        &nbsp;
      </div>
    );
  }
}

export default AdditionalInfo;
