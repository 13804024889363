import React from 'react';
import async from 'async';
import toastr from 'toastr';

import Vehicle from '../../Logic/Vehicle';

import './TakePhoto.css';

class TakePhoto extends React.Component {
  constructor(props) {
    super(props);

    this.convertImage = this.convertImage.bind(this);
    this.showImage = this.showImage.bind(this);
    this.submitPhoto = this.submitPhoto.bind(this);

    this.state = {
      image: null,
    };
  }

  setStateValue(prop, value) {
    const newState = { ...this.state };
    newState[prop] = value;

    this.setState(newState);
  }

  convertImage(event) {
    const file = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setStateValue('image', reader.result);
    };
    reader.onerror = (error) => {
      if (error) {
        toastr.error(`Something went wrong and we were unable to confirm the vehicle's details. Error: ${error.message}`, 'Oops');
      }
    };
  }

  showImage() {
    if (this.state.image !== null) {
      return (
        <div className="form-group">
          <img src={this.state.image} className="col-xs-12" alt="licence-disc" />
        </div>
      );
    }
    return null;
  }

  submitPhoto() {
    async.waterfall([
      (callback) => {
        Vehicle.addLicenceDisc(this.state.image, callback);
      },
    ], (error) => {
      if (error) {
        toastr.error(`Something went wrong and we were unable to confirm the vehicle's details. Error: ${error.message}`, 'Oops');
      } else {
        toastr.success('Your photo has successfully been added.', 'Success');
      }
    });
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <input
            id="upload-photo"
            type="file"
            className="filestyle"
            accept="image/*"
            onChange={this.convertImage}
            data-text="Take Photo"
            data-input="false"
          />
        </div>
        {this.showImage()}
        <button className="btn btn-lg btn-success" onClick={this.submitPhoto}>Submit</button>
      </div>
    );
  }
}

export default TakePhoto;
