import React from 'react';
import { Route } from 'react-router-dom';

import ComplaintItem from './ComplaintItem/ComplaintItem';
import ClaimStatus from '../common/ClaimStatus/ClaimStatus';

import './Complaints.css';

const Complaints = () => (
  <div>
    <div className="row">
      <ClaimStatus />
    </div>
    <div className="row" id="complaints">
      <h4 className="col-xs-12" id="complaints-header">
        Complaints
      </h4>
    </div>
    <Route
      render={({ history }) => (
        <div className="row" id="complaints-buttons">
          <div className="col-xs-2" />
          <ComplaintItem
            buttonText="Request your agent contact you"
            iconClass="request-call"
            onClick={() => { history.push('/requestcontact'); }}
          />
          <ComplaintItem
            buttonText="Message our complaints team"
            iconClass="message-complaints"
            onClick={() => { history.push('/sendmessage'); }}
          />
        </div>
      )}
    />
  </div>
);

export default Complaints;
