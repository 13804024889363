import React from 'react';
import async from 'async';
import { Route } from 'react-router-dom';
import toastr from 'toastr';
import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';

import Database from '../../Logic/Database';
import ServiceRating from '../../Logic/ServiceRating';
import ComplaintItem from '../Complaints/ComplaintItem/ComplaintItem';
import ClaimStatus from '../common/ClaimStatus/ClaimStatus';

import './RateUs.css';

class RateUs extends React.Component {
  constructor(props) {
    super(props);

    this.fetchRating = this.fetchRating.bind(this);
    this.selectStar = this.selectStar.bind(this);
    this.rateOne = this.rateOne.bind(this);
    this.setRatingValue = this.setRatingValue.bind(this);
    this.saveRating = this.saveRating.bind(this);

    this.state = {
      currentRating: 10,
    };
  }

  componentDidMount() {
    this.fetchRating();
  }

  setRatingValue(value) {
    this.setState({
      ...this.state,
      currentRating: value,
    });
  }

  fetchRating() {
    async.waterfall([
      ServiceRating.FetchServiceRating,
      Database.WriteServiceRating,
      Database.FetchServiceRating,
    ], (error, serviceRating) => {
      if (error) {
        toastr.error(`Something went wrong and we were unable to fetch the service rating. Error: ${error.message}`, 'Oops');
      } else {
        this.setState({
          currentRating: serviceRating.Rating,
          wouldRecommend: serviceRating.WouldRecommend,
        });
      }
    });
  }

  selectStar(rating) {
    this.setState({
      ...this.state,
      currentRating: rating,
    });
  }

  rateOne() {
    if (this.state.currentRating > 0 && this.state.currentRating < 3) {
      return (
        <div className="row grey-cell">
          <div className="col-xs-12">
            <p>
              OH No! Please help us understand what happened.
              Send us a Message or Call your agent directly.
            </p>
          </div>
          <Route
            render={({ history }) => (
              <div className="col-xs-12">
                <div className="row" id="complaints-buttons">
                  <ComplaintItem
                    buttonText="Message our complaints team"
                    iconClass="message-complaints"
                    onClick={() => { history.push('/sendmessage'); }}
                    offsetClass="col-xs-offset-2"
                  />
                  <ComplaintItem
                    buttonText="Call your agent"
                    iconClass="call-agent"
                    onClick={() => { history.push('/callagent'); }}
                  />
                </div>
              </div>
            )}
          />
        </div>
      );
    }
    return null;
  }

  saveRating() {
    async.waterfall([
      (callback) => {
        Database.SetRating(this.state.currentRating, callback);
      },
      ServiceRating.UpdateRating,
      Database.ServiceRatingSynchronised,
    ], (error) => {
      if (error) {
        toastr.error(`Something went wrong and we were unable to set the rating. Error: ${error.message}`, 'Oops');
      } else {
        toastr.success('Successfully submitted rating.', 'Thank you');
      }
    });
  }

  render() {
    return (
      <div>
        <div className="row">
          <ClaimStatus />
        </div>
        <div className="row" id="rate-us">
          <div className="col-xs-12">
            <h4 id="rate-us-header">Rate Us</h4>
            <div>
              On a scale of 1 to 10, how likely are you to recommend
              Autoboys service to family and friends?
            </div>
            <div className="col-xs-12 slider">
              <Slider
                value={this.state.currentRating}
                orientation="horizontal"
                onChange={this.selectStar}
                max={10}
              />
              <div className="value">{this.state.currentValue}</div>
              <button className="btn btn-success" onClick={this.saveRating}>Submit</button>
            </div>
          </div>
        </div>
        {this.rateOne()}
      </div>
    );
  }
}

export default RateUs;
