import axios from 'axios-es6';

import Database from './Database';
import global from '../global';

const FetchServiceRating = (callback) => {
  if (navigator.onLine) {
    try {
      Database.FetchCustAppId()
        .then((CustAppId) => axios.get(`${global.BASE_URL}CustomerApp/FetchServiceRating/${CustAppId}`))
        .then((data) => {
          callback(null, data.data);
        })
        .catch((error) => {
          callback(error);
        });
    } catch (error) {
      callback(error.message);
    }
  } else {
    callback(null, null);
  }
};

const UpdateRating = (rating, callback) => {
  if (navigator.onLine) {
    Database.FetchCustAppId()
      .then((CustAppId) => axios.post(`${global.BASE_URL}CustomerApp/UpdateRating/${CustAppId}/${rating}`))
      .then(() => {
        callback(null, true);
      })
      .catch((error) => {
        callback(error.responseText);
      });
  } else {
    callback(null, false);
  }
};

const ServiceRecommend = (recommend, callback) => {
  if (navigator.onLine) {
    Database.FetchCustAppId()
      .then((CustAppId) => axios.post(`${global.BASE_URL}CustomerApp/UpdateRecommend/${CustAppId}/${recommend}`))
      .then(() => {
        callback(null, true);
      })
      .catch((error) => {
        callback(error.responseText);
      });
  } else {
    callback(null, false);
  }
};

export default {
  FetchServiceRating,
  UpdateRating,
  ServiceRecommend,
};
