import Parser from 'ua-parser-js';
import axios from 'axios-es6';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://grapi.mysid.co.za/api/' : 'https://qaapi.mysid.co.za/api/';

const DB_VER = 7;

const ExcessPaymentLaunchDate = new Date(2018, 9, 1);

const LogAccess = (CustAppId) => {
  try {
    const parser = new Parser();
    const results = parser.getResult();
    const logObject = {
      CustAppId,
      UserAgent: results.ua,
      Browser: results.browser.name,
      BrowserVersion: results.browser.version,
      OperatingSystem: results.os.name,
      OsVersion: results.os.version,
      Device: results.device.model,
      DeviceType: results.device.type,
      DeviceVendor: results.device.vendor,
    };

    axios.post(`${BASE_URL}CustomerApp/LogAccess`, logObject);
  } catch (error) {
    console.log('Failed to log access.');
  }
};

export default {
  BASE_URL,
  DB_VER,
  LogAccess,
  ExcessPaymentLaunchDate,
};
