/* eslint-disable */
import React from 'react';
import async from 'async';

import Claim from '../../Logic/Claim';
import Database from '../../Logic/Database';
import Comment from './Comment/Comment';
import ClaimStatus from '../common/ClaimStatus/ClaimStatus';

import './Notifications.css';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.getComments = this.getComments.bind(this);

    this.state = {
      comments: [],
    };
  }

  componentDidMount() {
    this.getComments();
  }

  getComments() {
    async.waterfall(
      [
        Claim.FetchComments,
        Database.WriteComments,
        Database.FetchComments,
      ],
      (error, data) => {
        this.setState({
          comments: data,
        });
      },
    );
  }

  render() {
    const commentList = this.state.comments.map((comment, i) => (
      <Comment
        User={comment.User}
        Role={comment.Role}
        Outlet={comment.Outlet}
        CreateDate={comment.CreateDate}
        Message={comment.Message}
        Index={i}
        key={i.toString()}
      />
    ));

    return (
      <div>
        <div className="row">
          <ClaimStatus />
        </div>
        <div id="updates">
          <h4>Your Claim Updates</h4>
          {commentList}
        </div>
      </div>
    );
  }
}

export default Notifications;
