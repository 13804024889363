import React from 'react';
import PropType from 'prop-types';

import TopNav from '../TopNav/TopNav';

import './Header.css';

// #region Assets
import logo from '../../../assets/images/logo.png';
// #endregion

const Header = (props) => (
  <header>
    <div>
      <div className="App-header row">
        <div className="col-xs-3" />
        <div className="col-xs-6">
          <img id="company-logo" alt="Autoboys" src={logo} className="center-block" />
        </div>
        <div className="col-xs-3" />
      </div>
    </div>
    {window.location.pathname.startsWith('/additionalinfo') ? null
      : (<TopNav addSteps={props.addSteps} />)}
  </header>
);

Header.propTypes = {
  addSteps: PropType.func.isRequired,
};

export default Header;
