import React from 'react';
import PropTypes from 'prop-types';

import Database from '../../../Logic/Database';
import Claim from '../../../Logic/Claim';
import HeaderButton from '../HeaderButton/HeaderButton';

import './Footer.css';

// #region Assets
import Complaints from '../../../assets/images/complaints.png';
import Call from '../../../assets/images/call-agent-yellow.png';
// #endregion

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.getClaimStatus = this.getClaimStatus.bind(this);
    this.callAgent = this.callAgent.bind(this);

    this.state = {
      claimStatus: 0,
      contactNumber: '',
    };
  }

  componentDidMount() {
    this.getClaimStatus();

    this.props.addSteps([
      {
        title: 'Complain / Rate Us',
        text: 'Have any feedback? Would you like to rate our service? This is the place to do so.',
        selector: '#ComplainFooter',
        position: 'top',
        style: {
          mainColor: '#1c3c6c',
          beacon: {
            inner: '#1c3c6c',
            outer: '#1c3c6c',
          },
        },
      },
      {
        title: 'Call Your Agent',
        text: 'Get in touch with the agent handling your claim.',
        selector: '#CallAgentFooter',
        position: 'top',
        style: {
          mainColor: '#1c3c6c',
          beacon: {
            inner: '#fcb823',
            outer: '#fcb823',
          },
        },
      },
    ]);
  }

  getClaimStatus() {
    Database.FetchAllData()
      .then((data) => {
        this.setState({
          claimStatus: data.Claim.Status,
          contactNumber: data.Outlet.ContactNumber,
        });
      });
  }

  callAgent() {
    Claim.PingContactUs();
    document.location.href = `tel:${this.state.contactNumber}`;
  }

  render() {
    return (
      <div id="footer" className="row ">
        <div className="col-xs-12">
          <HeaderButton
            id="ComplainFooter"
            buttonText={this.state.claimStatus < 5 ? 'Complain' : 'Rate Us'}
            iconClass={Complaints}
            linkto={this.state.claimStatus < 5 ? 'complaints' : 'rateus'}
          />
          <HeaderButton
            id="CallAgentFooter"
            buttonText="Call Agent"
            iconClass={Call}
            linkto="makecall"
            callFunc={this.callAgent}
          />
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  addSteps: PropTypes.func.isRequired,
};

export default Footer;
