import React from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';

import './ProformaInvoice.css';

class ProformaInvoice extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: this.props.invoice,
      CreateExcessInvoicePDF: this.props.CreateExcessInvoicePDF,
      currentStatus: this.props.currentStatus,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      invoice: newProps.invoice,
      CreateExcessInvoicePDF: newProps.CreateExcessInvoicePDF,
      currentStatus: newProps.currentStatus,
    });
    // YUSSUF: Find HTML Tags with Regex, and Extract values to init Input Boxes
    let regex = /id="EICustomerName">.*<\/td>/;
    let tmpVal = newProps.invoice.match(regex);
    if (tmpVal != null) {
      tmpVal = tmpVal[0].replace(/id="EICustomerName">/, '').replace(/<\/td>/, '');
      document.getElementById('nameInput').value = tmpVal.trim();
    }
    regex = /id="EIAddress">((?!<)(.|\r\n))*<\/td>/;
    tmpVal = newProps.invoice.match(regex);
    if (tmpVal != null) {
      tmpVal = tmpVal[0].replace(/id="EIAddress">/, '').replace(/<\/td>/, '');
      document.getElementById('addressInput').value = tmpVal.trim();
    }
  }

  // YUSSUF: Change Name on Invoice to the value of the input box
  changeName() {
    document.getElementById('EICustomerName').innerText = document.getElementById('nameInput').value;
  }

  // YUSSUF: Change Address on Invoice to the value of the input box
  changeAddress() {
    document.getElementById('EIAddress').innerText = document.getElementById('addressInput').value;
  }

  render() {
    if (this.state.currentStatus > 3) {
      this.props.history.push('/');
    }
    // YUSSUF: dangerouslySetInnerHTML is used to set the innerHTML
    // to the html invoice pulled from the API (Which is not JSX)
    return (
      <div className="col-xs-12" id="proforma-invoice">
        <h4 className="text-left">Generate Proforma Invoice</h4>
        <form>
          <div className="form-group" id="InvoiceForm">
            <label htmlFor="nameInput">
              Name:
              <input
                type="text"
                className="form-control"
                id="nameInput"
                placeholder="Name"
                onChange={this.changeName}
              />
            </label>
            <br />
            <label htmlFor="addressInput">
              Address:
              <textarea
                className="form-control"
                id="addressInput"
                placeholder="Address"
                onChange={this.changeAddress}
              />
            </label>
            <br />
            <button
              className="btn btn-success btn-toolbar"
              type="button"
              id="btnEISubmit"
              onClick={() => {
                this.state.CreateExcessInvoicePDF(
                  document.getElementById('nameInput').value.trim(),
                  document.getElementById('addressInput').value.trim(),
                );
                document.getElementById('InvoiceForm').hidden = true;
                document.getElementById('InvoiceDisplay').hidden = true;
                document.getElementById('SaveNotice').hidden = false;
                setTimeout(() => { this.props.history.push('/'); }, 5000);
              }}
            >
              Save
            </button>
          </div>
          <div className="alert alert-success" role="alert" id="SaveNotice" hidden>
            Thank you for your payment.
            <br />
            The Invoice will be delivered by the Fitter during the allocated fitment date and time.
          </div>
        </form>
        <div className="row" id="InvoiceDisplay">
          <div
            className="col-xs-12"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: this.state.invoice }}
          />
        </div>
      </div>
    );
  }
}

ProformaInvoice.propTypes = {
  invoice: PropType.string,
  CreateExcessInvoicePDF: PropType.func,
  history: PropType.func.isRequired,
  currentStatus: PropType.number,
};

ProformaInvoice.defaultProps = {
  invoice: '<p>Loading</p>',
  CreateExcessInvoicePDF: null,
  currentStatus: 0,
};

export default withRouter(ProformaInvoice);
