import React from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'react-bootstrap-time-picker';

import './Fitment.css';

class TimeUpdateModal extends React.Component {
  constructor(props) {
    super(props);

    this.updateNewTime = this.updateNewTime.bind(this);

    this.state = {
      newTime: '08:00',
    };
  }

  updateNewTime() {
    this.props.UpdateTime(this.state.newTime);
  }

  render() {
    return (
      <div id="timeUpdateModal" className="modal fade">
        <div className="modal-dialog modal-confirm">
          <div className="modal-content">
            <div className="modal-header">
              <div className="icon-box">
                <i className="material-icons warning">error_outline</i>
              </div>
              <h4 className="modal-title">Select a new time:</h4>
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
                ×
              </button>
            </div>
            <div className="modal-body">
              <p>
                Please note - Your request reflects a preferred date/time of fitment and
                will only be confirmed once availability has been confirmed and one
                of our representatives contact you.
              </p>
              <p className="form-group">
                <TimePicker
                  start="08:00"
                  end="17:00"
                  step={30}
                  format={24}
                  value={this.state.newTime}
                  onChange={(time) => {
                    this.setState({
                      newTime: time,
                    });
                  }}
                />
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-info"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={this.updateNewTime}
                data-dismiss="modal"
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TimeUpdateModal.propTypes = {
  UpdateTime: PropTypes.func.isRequired,
};

export default TimeUpdateModal;
