import React from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import '../../../../node_modules/font-awesome/css/font-awesome.css';
import './ClaimStatusIndicator.css';

const containerStyle = {
  width: '20%',
  float: 'left',
};

class ClaimStatusIndicator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      claimStatusName: this.props.claimStatusName,
      isCompleted: this.props.isCompleted,
      helpText: this.props.helpText,
    };

    // this.showHelp = (
    //   <Popover id={this.state.claimStatusName} title={this.state.claimStatusName}>
    //     {this.state.helpText}
    //   </Popover>
    // );
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      claimStatusName: newProps.claimStatusName,
      isCompleted: newProps.isCompleted,
      helpText: newProps.helpText,
    });
  }

  render() {
    return (
      <div>
        <OverlayTrigger
          trigger="click"
          rootClose
          placement="bottom"
          overlay={(
            <Popover id={this.state.claimStatusName} title={this.state.claimStatusName}>
              {this.state.helpText}
            </Popover>
        )}
        >
          <div
            role="button"
            tabIndex={0}
            className="text-center"
            style={containerStyle}
            onClick={this.showHelp}
            onKeyPress={this.showHelp}
          >
            <FontAwesome name="circle" className={this.state.isCompleted ? 'circle-selected' : 'circle'} />
            <p className={this.state.isCompleted ? 'small status-selected' : 'small status'}>{this.state.claimStatusName}</p>
          </div>
        </OverlayTrigger>
      </div>
    );
  }
}

ClaimStatusIndicator.propTypes = {
  claimStatusName: PropTypes.string,
  isCompleted: PropTypes.bool,
  helpText: PropTypes.element.isRequired,
};

ClaimStatusIndicator.defaultProps = {
  claimStatusName: '',
  isCompleted: false,
};

export default ClaimStatusIndicator;
