import React from 'react';
import toastr from 'toastr';
import { Route } from 'react-router-dom';

import Claim from '../../../Logic/Claim';
import ClaimStatus from '../../common/ClaimStatus/ClaimStatus';

import './SendMessage.css';

class SendMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messageSent: false,
      hasError: false,
      message: '',
    };

    this.setStateValue = this.setStateValue.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
  }

  setStateValue(prop, value) {
    const newState = { ...this.state };
    newState[prop] = value;

    this.setState(newState);
  }

  hideAlert() {
    this.setStateValue('alert', null);
  }

  sendMessage() {
    if (this.state.message.length < 1) {
      toastr.warning('Please compose a message to send.', 'Oops');
    } else {
      Claim.SendComplaintsMessage(this.state.message)
        .then(() => {
          toastr.success("Your message is on it's way.", 'Success');
        })
        .catch(() => {
          toastr.error(
            'Something went wrong and we are unable to deliver your message.',
            'Oops',
          );
        });
    }
  }

  render() {
    return (
      <div className="row">
        <ClaimStatus />
        <div className="col-xs-12"><h4>Send a message</h4></div>
        <div className="col-xs-12 form-group">
          <textarea
            style={{ height: '25vh', fontSize: '16px' }}
            value={this.state.message}
            onChange={(event) => {
              this.setStateValue('message', event.target.value);
            }}
            className="form-control"
          />
          <Route
            render={({ history }) => (
              <div className="row" style={{ marginTop: '1em' }}>
                <div className="col-xs-6">
                  <button
                    onClick={() => {
                      this.sendMessage();
                      if (this.state.message.length >= 1) {
                        history.push('/complaints');
                      }
                    }}
                    className="btn btn-success btn-lg col-xs-12"
                  >
                    Send
                  </button>
                </div>
                <div className="col-xs-6">
                  <button
                    onClick={() => {
                      history.push('/complaints');
                    }}
                    className="btn btn-warning btn-lg col-xs-12"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

export default SendMessage;
