import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import _ from 'lodash';
import Joyride from 'react-joyride';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import 'react-joyride/lib/react-joyride-compiled.css';

import GLOBAL from './global';
import Database from './Logic/Database';
import Claim from './Logic/Claim';

import VerifyVehicleReg from './Components/VerifyVehicleReg/VerifyVehicleReg';
import Home from './Components/Home/index';
import Header from './Components/common/Header/Header';
import Footer from './Components/common/Footer/Footer';
import Notifications from './Components/Notifications';
import Complaints from './Components/Complaints';
import RequestContact from './Components/Complaints/RequestContact/RequestContact';
import SendMessage from './Components/Complaints/SendMessage/SendMessage';
import Vehicle from './Components/Vehicle';
import TakePhoto from './Components/TakePhoto/TakePhoto';
import Fitment from './Components/Fitment';
import RateUs from './Components/RateUs';
import MakeCall from './Components/common/MakeCall/MakeCall';
import ExcessInvoice from './Components/ExcessInvoice/index';
import AdditionalInfo from './Components/AdditionalInfo/AdditionalInfo';

import './App.css';
import otters from './assets/images/otters.gif';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShowIntroTour: false,
      RerunConfirmations: '',
      joyrideOverlay: true,
      joyrideType: 'continuous',
      isReady: false,
      isRunning: false,
      stepIndex: 0,
      steps: [],
      selector: '',
      showAlert: false,
    };
  }

  UNSAFE_componentWillMount = async () => {
    try {
      // HANNES: Before we do anything, we need to make sure that the offline
      // database is at least created.
      await this.createDatabase();

      // HANNES: Only run the intro tour if it hasn't been shown before.
      this.state.ShowIntroTour = await Database.ShowIntroTour();

      if (this.state.ShowIntroTour && this.props.RegNoVerified && !this.state.isRunning) {
        setTimeout(() => {
          this.setState({
            ...this.state,
            isReady: true,
            isRunning: true,
          });
        }, 1500);
      }
    } catch (error) {
      this.props.errorOccured(true);
    }
    // YUSSUF: Fetch and Store Excess Payment Variables
    // YUSSUF:  Removed Payment Link as it has not been implemented as yet
    const paymentLink = 'https://autoboys.co.za';// await Claim.FetchPaymentLink();
    this.state.paymentLink = paymentLink;
    const isPaid = await Claim.IsExcessPaid();
    this.props.setPaymentLink(paymentLink);
    this.props.setPaymentStatus(isPaid);

    setTimeout(async function run() {
      console.log('Fetching updated claim information.');
      const claimData = await Claim.FetchClaimData();
      await Database.WriteInitialData(claimData);
      this.props.setClaimStatus(claimData.Claim.Status);
      this.props.setFitmentDate(!claimData.Fitment.FitmentDate ? '' : claimData.Fitment.FitmentDate);
      setTimeout(run, 600000);
    }, 600000);
  }

  onClickSwitch = (e) => {
    e.preventDefault();
    const el = e.currentTarget;
    const state = {};

    if (el.dataset.key === 'joyrideType') {
      this.joyride.reset();

      this.setState({
        isRunning: false,
      });

      setTimeout(() => {
        this.setState({
          isRunning: true,
        });
      }, 300);

      state.joyrideType = e.currentTarget.dataset.type;
    }

    if (el.dataset.key === 'joyrideOverlay') {
      state.joyrideOverlay = el.dataset.type === 'active';
    }

    this.setState(state);
  }

  next = () => {
    this.joyride.next();
  }

  // HANNES: Check if the offline database exists. If not, create it.
  // If it does, check if the vehicle registration number exists.
  createDatabase = async () => {
    try {
      const CustAppId = window.location.search.substr(1, window.location.search.length - 1);
      this.props.setCustAppId(CustAppId);

      const dbExists = await Database.DatabaseExists();
      if (!dbExists && _.isUndefined(CustAppId)) {
        this.props.errorOccured(true);
      } else {
        let isSantamClaim = false;

        if (CustAppId.length > 0) {
          GLOBAL.LogAccess(CustAppId);
          // HANNES: Is this claim a Santam claim.
          isSantamClaim = await Claim.IsSantamClaim(CustAppId);
        }

        await Database.CreateLocalDatabase(dbExists);

        if (!isSantamClaim) {
          // HANNES: Claim Id accuired. Now check if the vehicle reg has been
          // verified.
          const regIsVerified = await Database.IsVehicleRegVerified();
          await this.props.verifyReNo(regIsVerified);

          if (regIsVerified) {
            const claimData = await Claim.FetchClaimData();
            await Database.WriteInitialData(claimData);
            this.props.setFitmentDate(!claimData.Fitment.FitmentDate ? '' : claimData.Fitment.FitmentDate);
            this.props.setClaimStatus(claimData.Claim.Status);
          }
        } else {
          const claimData = await Claim.FetchClaimData();
          await Database.WriteInitialData(claimData);

          this.props.verifyReNo(true);
          this.props.setFitmentDate(!claimData.Fitment.FitmentDate ? '' : claimData.Fitment.FitmentDate);
          this.props.setClaimStatus(claimData.Claim.Status);
        }
      }
    } catch (error) {
      this.props.errorOccured(true);
    }
  }

  joyrideCallback = async (data) => {
    console.log('%ccallback', 'color: #47AAAC; font-weight: bold; font-size: 13px;'); // eslint-disable-line no-console
    console.log(data); // eslint-disable-line no-console

    this.setState({
      ...this.state,
      selector: data.type === 'tooltip:before' ? data.step.selector : '',
    });

    if (data.type === 'finished') {
      // Hannes: The tour is finished. We don't want to show it again,
      // So we update the AppSettings.
      await Database.DisableIntroTour();
    }
  }

  addSteps = (steps) => {
    let newSteps = steps;

    if (!Array.isArray(newSteps)) {
      newSteps = [newSteps];
    }

    if (!newSteps.length) {
      return;
    }

    this.state.steps = this.state.steps.concat(newSteps);
  }

  render = () => {
    let websiteContext = null;
    if (this.props.ErrorOccured) {
      // Something went wrong
      websiteContext = (
        <div>
          <h3>Something went wrong.</h3>
          <p>
            Sorry for the inconvenience. We&apos;ll get right on it. In the mean
            time, here&apos;s an image of a some cute otters.
          </p>
          <div className="col-xs-12">
            <img alt="Otters" src={otters} style={{ width: '100%', maxWidth: '500px' }} />
          </div>
        </div>
      );
    } else if (!this.props.RegNoVerified || !this.props.CustAppId) {
      websiteContext = (
        <div>
          <VerifyVehicleReg />
        </div>
      );
    } else if (this.props.RegNoVerified) {
      const {
        isRunning,
        joyrideOverlay,
        joyrideType,
        stepIndex,
        steps,
      } = this.state;
      websiteContext = (
        <Router id="MainRouter">
          <div>
            <Joyride
              ref={(c) => (this.joyride = c)}
              callback={this.joyrideCallback}
              debug={false}
              disableOverlay={false}
              locale={{
                back: (<span>Back</span>),
                close: (<span>Close</span>),
                last: (<span>Last</span>),
                next: (<span>Next</span>),
                skip: (<span>Skip</span>),
              }}
              run={isRunning}
              showOverlay={joyrideOverlay}
              showSkipButton
              showStepsProgress
              stepIndex={stepIndex}
              steps={steps}
              type={joyrideType}
              autoStart={!window.location.pathname.startsWith('/additionalinfo')}
              holePadding={0}
              scrollToSteps={false}
            />
            <Header addSteps={this.addSteps} />
            <div>
              <Route exact path="/" component={Home} />
              <Route path="/notifications" component={Notifications} />
              <Route path="/complaints" component={Complaints} />
              <Route path="/requestcontact" component={RequestContact} />
              <Route path="/sendmessage" component={SendMessage} />
              <Route path="/vehicle" component={Vehicle} />
              <Route path="/takephoto" component={TakePhoto} />
              <Route path="/fitment" component={Fitment} />
              <Route path="/rateus" component={RateUs} />
              <Route path="/makecall" component={MakeCall} />
              <Route path="/additionalinfo" component={AdditionalInfo} />
              <Route
                path="/makepayment"
                component={() => window.location = this.state.paymentLink}
              />
              <Route path="/excessinvoice" component={ExcessInvoice} />
            </div>
            <Footer addSteps={this.addSteps} />
          </div>
        </Router>
      );
    }

    return (
      <div className="App container">
        { websiteContext }
      </div>
    );
  }
}

App.propTypes = {
  RegNoVerified: PropTypes.bool.isRequired,
  verifyReNo: PropTypes.func.isRequired,
  errorOccured: PropTypes.func.isRequired,
  ErrorOccured: PropTypes.bool.isRequired,
  setCustAppId: PropTypes.func.isRequired,
  setClaimStatus: PropTypes.func.isRequired,
  CustAppId: PropTypes.string.isRequired,
  setFitmentDate: PropTypes.func.isRequired,
  setPaymentLink: PropTypes.func.isRequired,
  setPaymentStatus: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  RegNoVerified: state.RegNoVerified,
  ErrorOccured: state.ErrorOccured,
  CustAppId: state.CustAppId,
});

const mapDispatchToProps = (dispatch) => ({
  verifyReNo: async (verified) => {
    await dispatch({ type: 'VERIFY_VEHICLE_REG', value: verified });
  },
  errorOccured: (hasError) => {
    dispatch({ type: 'ERROR_OCCURED', value: hasError });
  },
  setCustAppId: (CustAppId) => {
    dispatch({ type: 'SET_CUSTAPPID', value: CustAppId });
  },
  setClaimStatus: (claimStatus) => {
    dispatch({ type: 'SET_CLAIM_STATUS', value: claimStatus });
  },
  setFitmentDate: (fitmentDate) => {
    dispatch({ type: 'SET_FITMENT_DATE', value: fitmentDate });
  },
  setPaymentLink: (paymentLink) => {
    dispatch({ type: 'SET_PAYMENT_LINK', value: paymentLink });
  },
  setPaymentStatus: (paymentStatus) => {
    dispatch({ type: 'SET_PAYMENT_STATUS', value: paymentStatus });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
