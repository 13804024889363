import axios from 'axios-es6';
import moment from 'moment';

import Database from './Database';
import global from '../global';

const FetchFitmentDetails = (callback) => {
  if (navigator.onLine) {
    Database.FetchCustAppId()
      .then((CustAppId) => axios.get(`${global.BASE_URL}CustomerApp/FetchFitmentDetails/${CustAppId}`))
      .then((fitment) => {
        callback(null, fitment.data);
      })
      .catch((error) => {
        callback(error);
      });
  } else {
    callback(null, null);
  }
};

const FetchFitmentOptions = (Lat, Lng, callback) => {
  if (navigator.onLine) {
    Database.FetchCustAppId()
      .then((CustAppId) => axios.get(`${global.BASE_URL}CustomerApp/FetchFitmentOptions/${encodeURIComponent(CustAppId)}?Lat=${encodeURIComponent(Lat)}&Lng=${encodeURIComponent(Lng)}`))
      .then((fitment) => {
        callback(null, fitment.data);
      })
      .catch((error) => {
        callback(error);
      });
  } else {
    callback(null, null);
  }
};

const ConfirmAddress = (addressType, callback) => {
  if (navigator.onLine) {
    Database.FetchCustAppId()
      .then((CustAppId) => axios.post(`${global.BASE_URL}CustomerApp/ConfirmAddress/${CustAppId}/${addressType}`))
      .then(() => {
        callback(null);
      })
      .catch((error) => {
        callback(error);
      });
  } else {
    callback(null);
  }
};

const UpdateFitmentTime = (newTime) => new Promise(async (resolve, reject) => {
  try {
    const newTimeString = moment('1900-01-01').add(newTime, 's').format('HH:mm');
    const CustAppId = await Database.FetchCustAppId();
    const result = await axios.post(`${global.BASE_URL}CustomerApp/SaveNewFitmentTime`, {
      CustAppId,
      NewTime: newTimeString,
    });
    resolve(result.data);
  } catch (error) {
    reject(error);
  }
});

const UpdateFitmentAddress = (newAddress) => new Promise(async (resolve, reject) => {
  try {
    const CustAppId = await Database.FetchCustAppId();
    const result = await axios.post(`${global.BASE_URL}CustomerApp/SaveNewFitmentAddress`, {
      CustAppId,
      NewAddress: newAddress,
    });
    resolve(result.data);
  } catch (error) {
    reject(error);
  }
});

const UpdateLocation = (newAddress, newLat, newLng) => new Promise(async (resolve, reject) => {
  try {
    const CustAppId = await Database.FetchCustAppId();
    const result = await axios.post(`${global.BASE_URL}CustomerApp/NewLocation`, {
      CustAppId,
      address: newAddress,
      lat: newLat,
      lng: newLng,
    });
    resolve(result.data);
  } catch (error) {
    reject(error);
  }
});

export default {
  FetchFitmentDetails,
  ConfirmAddress,
  UpdateFitmentTime,
  UpdateFitmentAddress,
  UpdateLocation,
  FetchFitmentOptions,
};
