import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import Geocode from 'react-geocode';
import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng,
} from 'react-places-autocomplete';
import GoogleMap from 'google-map-react';
import Marker from '../../assets/images/marker-alt.png';

import './Fitment.css';

class CurrentLocationModal extends React.Component {
  constructor(props) {
    super(props);

    this.updateLocation = this.updateLocation.bind(this);
    this.getMapAddress = this.getMapAddress.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.setMarker = this.setMarker.bind(this);

    this.state = {
      newAddress: '',
      newLat: '',
      newLng: '',
    };
    Geocode.setApiKey('AIzaSyA5Ym8kHQsA0l6GY5g85brhV5MLMCglGRY');

    this.getLocation();
  }

  setMarker() {
    document.getElementById('marker').style.left = `Calc(${document.getElementById('mapDiv').offsetLeft + (document.getElementById('mapDiv').offsetWidth / 2)}px - 1em)`;
    document.getElementById('marker').style.top = `Calc(${document.getElementById('mapDiv').offsetTop + (document.getElementById('mapDiv').offsetHeight / 2)}px - 2em)`;
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const addressResult = await Geocode
            .fromLatLng(position.coords.latitude, position.coords.longitude);
          if (addressResult.results.length < 1 || !addressResult.results[0].formatted_address) { throw new Error('Address Not Found'); }
          this.setState({
            newLat: position.coords.latitude,
            newLng: position.coords.longitude,
            newAddress: addressResult.results[0].formatted_address,
          });
        },
        (error) => {
          toastr.error(error.message);
        },
      );
    } else {
      toastr.error('Geolocation is not supported by this browser.');
    }
  }

  async getMapAddress() {
    const addressResult = await Geocode
      .fromLatLng(this.state.newLat, this.state.newLng);
    if (addressResult.results.length < 1 || !addressResult.results[0].formatted_address) { throw new Error('Address Not Found'); }
    this.setState({
      newAddress: addressResult.results[0].formatted_address,
    });
  }

  handleChange = (address) => {
    this.setState({ newAddress: address });
  };

  handleSelect = (address, temp) => {
    this.setState({ newAddress: address });
    geocodeByPlaceId(temp)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => this.setState({ newLat: latLng.lat, newLng: latLng.lng }))
      .catch((error) => console.error('Error', error));
  };

  updateLocation() {
    this.props.SendLocation(this.state.newAddress, this.state.newLat, this.state.newLng);
    toastr.success(`Location changed to ${this.state.newAddress}`);
  }

  render() {
    return (
      <div id="currentLocationModal" className="modal fade">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Send Location</h4>
            </div>
            <div className="modal-body">
              <PlacesAutocomplete
                value={this.state.newAddress}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                searchOptions={{ componentRestrictions: { country: 'za' } }}
              >
                {({
                  getInputProps, suggestions, getSuggestionItemProps, loading,
                }) => (
                  <div>
                    <form className="form-horizontal">
                      <h5 style={{ textAlign: 'left', marginBottom: '20px' }} className="col-xs-12">Type to search for an address:</h5>
                      <div className="form-group row">
                        <div className="col-xs-12">
                          <input
                            style={{ marginBottom: '15px' }}
                            {...getInputProps({
                              placeholder: 'Search Places ...',
                              className: 'form-control col-xs-4',
                            })}
                          />
                        </div>
                        <div className="col-xs-12 reset-location-btn">
                          <button
                            style={{ width: '50%' }}
                            type="button"
                            className="btn btn-primary form-control col-xs-4"
                            onClick={this.getLocation}
                          >
                            Reset to Current Location
                          </button>
                        </div>
                      </div>
                    </form>
                    <div style={{ position: 'relative', zIndex: '4' }}>
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => (
                        <button
                          type="button"
                          {...getSuggestionItemProps(suggestion, {
                            className: 'btn btn-primary col-xs-12',
                            style: { margin: '3px' },
                          })}
                          key={suggestion.placeId}
                        >
                          {suggestion.description}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div style={{ height: '60vh', width: '100%' }} id="mapDiv">
              <GoogleMap
                apiKey="AIzaSyA5Ym8kHQsA0l6GY5g85brhV5MLMCglGRY"
                center={{ lat: this.state.newLat, lng: this.state.newLng }}
                zoom={20}
                options={{
                  mapTypeControl: false,
                  mapTypeId: 'satellite',
                  streetViewControl: false,
                  zoomControl: false,
                  minZoom: 20,
                  maxZoom: 20,
                }}
                onDragEnd={(c) => this.setState(
                  { newLat: c.center.lat(), newLng: c.center.lng() },
                  this.getMapAddress,
                )}
                onTilesLoaded={this.setMarker}
              />
            </div>
            <img
              id="marker"
              src={Marker}
              alt="⯆"
              style={{
                width: '2em',
                position: 'fixed',
              }}
            />
            <div className="modal-footer reset-location-btn row">
              <div className="col-xs-6">
                <button
                  type="button"
                  className="btn  btn-info-footer"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
              </div>
              <div className="col-xs-6">
                <button
                  type="button"
                  className="btn  btn-succuss-footer "
                  onClick={this.updateLocation}
                  data-dismiss="modal"
                  disabled={!this.state.newAddress}
                >
                  Set as fitment location
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CurrentLocationModal.propTypes = {
  SendLocation: PropTypes.func.isRequired,
};

export default CurrentLocationModal;
