import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from 'sweetalert-react';
import { renderToStaticMarkup } from 'react-dom/server';
import _ from 'lodash';
import { connect } from 'react-redux';

import ClaimStatus from '../common/ClaimStatus/ClaimStatus';
import OutletDetails from './OutletDetails/OutletDetails';
import InsuredDetails from './InsuredDetails/InsuredDetails';
import ClaimDetails from './ClaimDetails/ClaimDetails';

import Database from '../../Logic/Database';

import '../../../node_modules/sweetalert/dist/sweetalert.css';
import './Home.css';

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.checkConfirmations = this.checkConfirmations.bind(this);

    this.state = {
      Insured: {},
      Claim: {},
      Outlet: {},
      Vehicle: {},
      Fitment: {},
      alert: null,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.checkConfirmations();
  }

  fetchData() {
    let claimData = {};
    Database.FetchAllData()
      .then((data) => {
        claimData = data;
        return Database.AllDataConfirmed();
      })
      .then((allDataConfirmed) => {
        let alert = null;
        if (!allDataConfirmed) {
          alert = (
            <div className="text-left">
              <p>Welcome to the Autoboys Claims App.</p>
              <p>
                Here you can keep up to date with all your claim activity.
              </p>
              <p>
                Please confirm your
                {' '}
                <b>Vehicle Detail</b>
                {' '}
                and your
                <b> Fitment Address</b>
                , to ensure we service you correctly.
              </p>
            </div>
          );
        }

        this.setState({
          Insured: claimData.Insured,
          Claim: claimData.Claim,
          Outlet: claimData.Outlet,
          Vehicle: claimData.Vehicle,
          Fitment: claimData.Fitment,
          alert,
        });
      })
      .catch((error) => {
        console.log(error);
        this.props.errorOccured(true);
      });
  }

  checkConfirmations() {
    try {
      Database.FetchVehicle((error, vehicle) => {
        if (!error) {
          this.props.confirmVehicle(vehicle.Confirmed);
          Database.FetchFitment((err1, fitment) => {
            if (!err1) {
              this.props.confirmAddress(fitment.Confirmed);
            }
          });
        }
      });
    } catch (error) {
      console.log(error);
      this.props.errorOccured(true);
    }
  }

  render() {
    return (
      <div id="Home" className="row">
        <ClaimStatus />
        <OutletDetails
          BranchName={this.state.Outlet.Outlet}
          Csc={this.state.Outlet.AssignedAgent}
          ContactNumber={this.state.Outlet.ContactNumber}
          fitmentAddress={!_.isUndefined(this.state.Fitment) ? this.state.Fitment.Address : ''}
        />
        <InsuredDetails
          ClaimNumber={this.state.Insured.ClaimNumber}
          InsuredName={this.state.Insured.Name}
          InsuredCell={this.state.Insured.CellNumber}
        />
        <ClaimDetails
          ClaimId={this.state.Claim.ClaimId}
          CustAppId={this.state.Claim.CustAppId}
          ClaimType={this.state.Claim.ClaimType}
          GlassPosition={this.state.Claim.GlassPosition}
          CauseOfLoss={this.state.Claim.CauseOfLoss}
          Excess={this.state.Claim.Excess}
          ExcessActual={this.state.Claim.ExcessActual}
          PaymentStatus={this.props.PaymentStatus}
          PaymentLink={this.props.PaymentLink}
          currentStatus={this.props.currentStatus}
          DateAuthorised={this.state.Claim.DateAuthorised}
        />
        <SweetAlert
          show={!sessionStorage.getItem('welcomeShown') && !_.isNull(this.state.alert)}
          title="Confirm Details"
          html
          text={!_.isNull(this.state.alert) ? renderToStaticMarkup(this.state.alert) : ''}
          onConfirm={() => {
            sessionStorage.setItem('welcomeShown', true);
            this.setState({ ...this.state, alert: null });
          }}
        />
      </div>
    );
  }
}

Home.propTypes = {
  errorOccured: PropTypes.func.isRequired,
  confirmVehicle: PropTypes.func.isRequired,
  confirmAddress: PropTypes.func.isRequired,
  PaymentStatus: PropTypes.bool.isRequired,
  PaymentLink: PropTypes.string.isRequired,
  currentStatus: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  PaymentStatus: state.paymentStatus,
  PaymentLink: state.paymentLink,
  currentStatus: state.currentStatus,
});

const mapDispatchToProps = (dispatch) => ({
  confirmVehicle: (confirmed) => {
    dispatch({ type: 'CONFIRM_VEHICLE', value: confirmed });
  },
  confirmAddress: (confirmed) => {
    dispatch({ type: 'CONFIRM_ADDRESS', value: confirmed });
  },
  errorOccured: (hasError) => {
    dispatch({ type: 'ERROR_OCCURED', value: hasError });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
