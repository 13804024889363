import React from 'react';
import PropType from 'prop-types';
import { withRouter } from 'react-router-dom';
import global from '../../../global';

import './ClaimDetails.css';

class ClaimDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClaimId: this.props.ClaimId,
      CustAppId: this.props.CustAppId,
      ClaimType: this.props.ClaimType,
      GlassPosition: this.props.GlassPosition,
      CauseOfLoss: this.props.CauseOfLoss,
      Excess: this.props.Excess,
      ExcessActual: this.props.ExcessActual,
      DateAuthorised: this.props.DateAuthorised,
      PaymentStatus: this.props.PaymentStatus,
      PaymentLink: this.props.PaymentLink,
      currentStatus: this.props.currentStatus,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      ClaimId: newProps.ClaimId,
      CustAppId: newProps.CustAppId,
      ClaimType: newProps.ClaimType,
      GlassPosition: newProps.GlassPosition,
      CauseOfLoss: newProps.CauseOfLoss,
      Excess: newProps.Excess,
      ExcessActual: newProps.ExcessActual,
      DateAuthorised: newProps.DateAuthorised,
      PaymentStatus: newProps.PaymentStatus,
      PaymentLink: newProps.PaymentLink,
      currentStatus: newProps.currentStatus,
    });
  }

  render() {
    // YUSSUF: Setup button that will direct user to payment page
    const styleButton = {
      color: '#4d4d4d',
      background: '#fcb823',
      border: 'none',
      padding: '1em',
      fontWeight: 'bold',
    };
    const styleExcess = {
      color: this.state.PaymentStatus || this.state.Excess === '0.00' || this.state.currentStatus < 2 ? 'green' : 'red',
    };
    const hrefButton = () => this.props.history.push('/makepayment');
    const btnPay = this.state.PaymentStatus
    || this.state.Excess === '0.00'
    || this.state.currentStatus < 2
    || this.state.PaymentLink === ''
    || new Date(this.state.DateAuthorised) <= global.ExcessPaymentLaunchDate
      ? null
      : (
        <button style={styleButton} onClick={hrefButton}>
          Pay R
          {this.state.ExcessActual}
        </button>
      );
    const tmpExcess = this.state.currentStatus < 2 ? 'Awaiting confirmation' : (
      <span style={styleExcess}>
        R
        {this.state.Excess}
        {' '}
        {this.state.PaymentStatus && this.state.Excess !== '0.00' ? '(PAID)' : ''}
      </span>
    );
    return (
      <div className="col-xs-12" id="claim-details">
        <h4 className="text-left">
          Claim Details: ID
          {this.state.ClaimId}
        </h4>
        <h5 className="hidden">
          Reference ID:
          {' '}
          {this.state.CustAppId}
        </h5>
        <div className="row">
          <div className="col-xs-5 small text-left">
            <b>Claim Type:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {this.state.ClaimType}
          </div>
          <div className="col-xs-5 small text-left">
            <b>Glass Position:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {this.state.GlassPosition}
            {' '}
            <br />
          </div>
          <div className="col-xs-5 small text-left">
            <b>Cause Of Loss:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {this.state.CauseOfLoss}
            {' '}
            <br />
          </div>
          <div className="col-xs-5 small text-left">
            <b>Excess:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {tmpExcess}
          </div>
          <div className="col-xs-5 small text-left" />
          <div className="col-xs-7 small text-left">
            {btnPay}
          </div>
        </div>
      </div>
    );
  }
}

ClaimDetails.propTypes = {
  ClaimId: PropType.number,
  CustAppId: PropType.string,
  ClaimType: PropType.string,
  GlassPosition: PropType.string,
  CauseOfLoss: PropType.string,
  Excess: PropType.string,
  ExcessActual: PropType.string,
  DateAuthorised: PropType.string,
  PaymentStatus: PropType.bool,
  PaymentLink: PropType.string,
  currentStatus: PropType.number,
  history: PropType.func.isRequired,
};

ClaimDetails.defaultProps = {
  ClaimId: 0,
  CustAppId: '',
  ClaimType: '',
  GlassPosition: '',
  CauseOfLoss: '',
  Excess: '0.00',
  ExcessActual: '0.00',
  DateAuthorised: '2012/01/01',
  PaymentStatus: false,
  PaymentLink: '',
  currentStatus: 0,
};

export default withRouter(ClaimDetails);
