import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import phoneFormatter from 'node-phone-formatter';

import './InsuredDetails.css';

class InsuredDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ClaimNumber: this.props.ClaimNumber,
      InsuredName: this.props.InsuredName,
      InsuredCell: this.props.InsuredCell,
    };
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({
      ClaimNumber: newProps.ClaimNumber,
      InsuredName: newProps.InsuredName,
      InsuredCell: newProps.InsuredCell,
    });
  }

  render() {
    return (
      <div className="col-xs-12" id="insured-details">
        <h4 className="text-left">Insured Details</h4>
        <div className="row">
          <div className={!_.isNull(this.state.ClaimNumber) ? '' : 'hidden'}>
            <div className="col-xs-5 small text-left">
              <b>Claim Number:</b>
            </div>
            <div className="col-xs-7 small text-left">
              {this.state.ClaimNumber}
            </div>
          </div>
          <div className="col-xs-5 small text-left">
            <b>Insured Name:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {this.state.InsuredName}
          </div>
          <div className="col-xs-5 small text-left">
            <b>Insured Cell:</b>
          </div>
          <div className="col-xs-7 small text-left">
            {phoneFormatter.format(this.state.InsuredCell, 'NNN NNN NNNN')}
          </div>
        </div>
      </div>
    );
  }
}

InsuredDetails.propTypes = {
  ClaimNumber: PropTypes.string,
  InsuredName: PropTypes.string,
  InsuredCell: PropTypes.string,
};

InsuredDetails.defaultProps = {
  ClaimNumber: '',
  InsuredName: '',
  InsuredCell: '',
};

export default InsuredDetails;
